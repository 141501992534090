import React, { useContext, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { getUser, updateUserData } from "../../../../api";
import { AppContext } from "../../../../context/AppContext";
import moment from "moment";
import validate from "../../../../utils/formValidator";
import "./index.css";

const ProfileDetails = () => {
  const { profile, store, setShowToast, setToastData, updateUser } =
    useContext(AppContext);
  const [profileData, setProfileData] = useState({});
  const [checkValidation, setCheckValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleSaveProfile = () => {
    setCheckValidation(true);
    if (
      profileData?.email === "" ||
      profileData?.firstname === "" ||
      profileData?.lastname === "" ||
      profileData?.dob === ""
    ) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: "Please fill all required Fields"
      });
      return 0;
    }
    if (!validate(profileData?.email.trim(), "email")) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: "Invalid Email Format"
      });
      return 0;
    }
    setLoading(true);
    updateUserData({
      customer: {
        email: profileData?.email?.toLowerCase(),
        firstname: profileData?.firstname,
        lastname: profileData?.lastname,
        website_id: store?.website_id,
        dob: profileData?.dob,
        gender: profileData?.gender,
        custom_attributes: [
          {
            attribute_code: "mobile_number",
            value: profile?.custom_attributes?.filter(
              item => item?.attribute_code === "mobile_number"
            )[0]?.value
          }
        ]
      }
    })
      .then(() => {
        getUser().then(res => {
          updateUser(res);
          setShowToast(true);
          setToastData({
            type: "success",
            text: "Profile Updated"
          });
        });
      })
      .catch(e => {
        setShowToast(true);
        setToastData({
          type: "error",
          text: e.message
        });
      })
      .finally(() => {
        setLoading(false);
        setEdit(false);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      });
  };
  useEffect(() => {
    getUser().then(userres => {
      updateUser(userres);
    });
  }, []);
  useEffect(() => {
    setProfileData({
      firstname: profile?.firstname,
      lastname: profile?.lastname,
      dob: profile?.dob,
      gender: profile?.gender,
      email: profile?.email?.toLowerCase()
    });
  }, [profile]);
  return (
    <div className="item-container">
      <div className="py-2">
        <div className="profile-title px-lg-5 px-2 py-4 solid border-bottom">
          <span>Personal Information</span>
          {!edit && (
            <button
              className="btn profile-button-inactive fs-10 fw-700 ml-4"
              onClick={() => setEdit(true)}
            >
              Edit
            </button>
          )}
        </div>
        {edit ? (
          <div className="form justify-center px-lg-5 p-4">
            <div className="row">
              <div className="my-1 col-lg-6">
                <label className="profile-label">
                  First Name
                  {checkValidation && profileData?.firstname === "" && (
                    <span className="required">*required</span>
                  )}
                </label>
                <input
                  type="text"
                  maxLength="20"
                  className="form-control"
                  placeholder="eg: John"
                  onChange={e => {
                    setProfileData({
                      ...profileData,
                      firstname: e.target.value
                    });
                  }}
                  defaultValue={profileData?.firstname}
                />
              </div>
              <div className="my-1 col-lg-6">
                <label className="profile-label">
                  Last Name
                  {checkValidation && profileData?.lastname === "" && (
                    <span className="required">*required</span>
                  )}
                </label>
                <input
                  maxLength="30"
                  type="text"
                  className="form-control"
                  placeholder="eg: Doe"
                  onChange={e => {
                    setProfileData({
                      ...profileData,
                      lastname: e.target.value
                    });
                  }}
                  defaultValue={
                    profileData?.lastname === "." ? "" : profileData?.lastname
                  }
                />
              </div>
              <div className="my-1 col-lg-12">
                <label className="profile-label">
                  Email
                  {checkValidation && profileData?.email === "" ? (
                    <span className="required">*required</span>
                  ) : (
                    checkValidation &&
                    !validate(
                      profileData?.email?.trim()?.toLowerCase(),
                      "email"
                    ) && <span className="required">*invalid email format</span>
                  )}
                </label>
                <input
                  maxLength="30"
                  type="email"
                  className="form-control"
                  placeholder="eg: Johndoe@gmail.com"
                  // disabled
                  onChange={e => {
                    setProfileData({
                      ...profileData,
                      email: e.target.value?.toLowerCase()
                    });
                  }}
                  defaultValue={profileData?.email?.toLowerCase()}
                />
              </div>
              <div className="my-1 col-lg-12">
                <label className="profile-label">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="eg: 4621321354"
                  disabled
                  defaultValue={
                    profile?.custom_attributes?.filter(
                      item => item?.attribute_code === "mobile_number"
                    )[0]?.value
                  }
                />
              </div>
              <div className="my-1 col-lg-6">
                <label className="profile-label">Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="eg: 25-4-1989"
                  onChange={e => {
                    setProfileData({
                      ...profileData,
                      dob: e.target.value
                    });
                  }}
                  defaultValue={profileData?.dob}
                />
              </div>
              <div className="my-1 col-lg-6">
                <label className="profile-label">Gender</label>
                <div className="row">
                  <div className="col-6">
                    <button
                      className={
                        "form-control profile-button " +
                        (profileData?.gender === 1
                          ? "profile-button-active"
                          : "profile-button-inactive")
                      }
                      onClick={() =>
                        setProfileData({
                          ...profileData,
                          gender: 1
                        })
                      }
                    >
                      Male
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className={
                        "form-control profile-button " +
                        (profileData?.gender === 0
                          ? "profile-button-active"
                          : "profile-button-inactive")
                      }
                      onClick={() =>
                        setProfileData({
                          ...profileData,
                          gender: 0
                        })
                      }
                    >
                      Female
                    </button>
                  </div>
                </div>
              </div>
              <div className="my-1 col-lg-6 mt-5 d-flex justify-content-around">
                <button
                  className="btn profile-button profile-button-inactive col-5"
                  onClick={() => {
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth"
                    });
                    setEdit(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn profile-save-button profile-button-active col-5"
                  onClick={() => {
                    !loading && handleSaveProfile();
                  }}
                >
                  {loading ? (
                    <Loader
                      type="TailSpin"
                      color={"white"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="justify-center px-lg-5 p-4">
            <div className="profile-details-card">
              <div className="fs-16 fw-900">Name:</div>
              <div className="fs-16 faded-color">
                {`${profileData?.firstname} ${profileData?.lastname}`}
              </div>
            </div>
            <div className="profile-details-card">
              <div className="fs-16 fw-900">Gender:</div>
              <div className="fs-16 faded-color">
                {profileData?.gender === 1 ? "Male" : "Female"}
              </div>
            </div>
            <div className="profile-details-card">
              <div className="fs-16 fw-900">Email:</div>
              <div className="fs-16 faded-color">{profileData?.email}</div>
            </div>
            <div className="profile-details-card">
              <div className="fs-16 fw-900">Phone:</div>
              <div className="fs-16 faded-color">
                {
                  profile?.custom_attributes?.filter(
                    item => item?.attribute_code === "mobile_number"
                  )[0]?.value
                }
              </div>
            </div>
            <div className="profile-details-card">
              <div className="fs-16 fw-900">Date of Birth:</div>
              <div className="fs-16 faded-color">
                {profileData?.dob
                  ? moment(profileData?.dob).format("DD-MMMM-yyyy")
                  : "DD-MM-YYYY"}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ProfileDetails;
