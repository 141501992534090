// imports
import axios from "axios";
/*============================================================*/

// declaring variables
let cancel;
/*============================================================*/

// creating axios instance
const createAxiosInstance = URL => {
  try {
    return axios.create({
      baseURL: URL,
      timeout: 30000
    });
  } catch (e) {
    console.log("Error Creating Axios Instance");
  }
};
/*============================================================*/

// creating config
const setUpConfig = async (isAuthenticated, multiPathConfig) => {
  try {
    const access_token = await localStorage.getItem("access_token");

    const CONFIG_WITH_AUTHORIZATION = {
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + access_token
      }
    };
    const CONFIG_WITHOUT_AUTHORIZATION = {
      headers: {
        "content-type": "application/json"
      }
    };
    const MULTIPATH_CONFIG_WITH_AUTHORIZATION = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + access_token
      }
    };
    const MULTIPATH_CONFIG_WITHOUT_AUTHORIZATION = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    return isAuthenticated && !multiPathConfig
      ? CONFIG_WITH_AUTHORIZATION
      : !isAuthenticated && !multiPathConfig
      ? CONFIG_WITHOUT_AUTHORIZATION
      : isAuthenticated && multiPathConfig
      ? MULTIPATH_CONFIG_WITH_AUTHORIZATION
      : MULTIPATH_CONFIG_WITHOUT_AUTHORIZATION;
  } catch (e) {
    console.log("Error Setting Config");
  }
};
/*============================================================*/

// error management
const networkErrorLogger = e => {
  if (axios.isCancel(e)) {
    throw e.message;
  }
  if (e.message === "Network Error") {
    throw "Network Error. Ensure you are connected to internet.";
  } else {
    if (
      e &&
      e.response &&
      e.response.data &&
      typeof e.response.data.message === "string"
    ) {
      throw e.response.data;
    } else {
      throw "Something went wrong, contact admin";
    }
  }
};
/*============================================================*/

//get method
const get = async (
  BASE_URL,
  API_URL,
  isAuthenticated = true,
  responseData = true
) => {
  try {
    let CONFIG = await setUpConfig(isAuthenticated);
    let apiResponse = await createAxiosInstance(BASE_URL).get(API_URL, CONFIG);
    return responseData ? apiResponse.data : apiResponse;
  } catch (error) {
    networkErrorLogger(error);
  }
};
/*============================================================*/

//post method
const post = async (
  BASE_URL,
  API_URL,
  PAYLOAD = {},
  isAuthenticated = true,
  multiPathConfig = false
) => {
  const apiCancelToken = axios.CancelToken.source();
  try {
    let CONFIG = await setUpConfig(isAuthenticated, multiPathConfig);
    let apiResponse = await createAxiosInstance(BASE_URL).post(
      API_URL,
      PAYLOAD,
      {
        ...CONFIG,
        cancelToken: apiCancelToken.token
      }
    );
    return apiResponse.data;
  } catch (error) {
    networkErrorLogger(error);
  }
};
/*============================================================*/

//put method
const put = async (
  BASE_URL,
  API_URL,
  PAYLOAD = {},
  isAuthenticated = true,
  multiPathConfig = false
) => {
  const apiCancelToken = axios.CancelToken.source();
  try {
    let CONFIG = await setUpConfig(isAuthenticated, multiPathConfig);
    let apiResponse = await createAxiosInstance(BASE_URL).put(
      API_URL,
      PAYLOAD,
      {
        ...CONFIG,
        cancelToken: apiCancelToken.token
      }
    );
    return apiResponse.data;
  } catch (error) {
    networkErrorLogger(error);
  }
};
/*============================================================*/

//delete method
const del = async (
  BASE_URL,
  API_URL,
  isAuthenticated = true,
  multiPathConfig = false
) => {
  try {
    let CONFIG = await setUpConfig(isAuthenticated, multiPathConfig);
    let apiResponse = await createAxiosInstance(BASE_URL).delete(
      API_URL,
      CONFIG
    );
    return apiResponse.data;
  } catch (error) {
    networkErrorLogger(error);
  }
};
/*============================================================*/

export { put, post, get, del };
