import React, { useContext, useEffect, useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { AppContext } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import "./ToastMessage.css";

function ToastMessage(props) {
  const { setShowToast, showToast } = useContext(AppContext);
  const { width } = useWindowSize();
  let icon;
  let color;

  switch (props?.type) {
    case "info":
      icon = <AiFillInfoCircle className="mr-3" size={width < 768 ? 26 : 20} />;
      color = "#354052";
      break;
    case "success":
      icon = <FaCheckCircle className="mr-3" size={width < 768 ? 26 : 20} />;
      color = "#159649";
      break;
    case "error":
      icon = <MdCancel className="mr-3" size={width < 768 ? 26 : 20} />;
      color = "#D93C37";
      break;
  }

  let toastTimeOut = null;
  useEffect(() => {
    toastTimeOut = null;
    toastTimeOut = setTimeout(() => {
      setShowToast(false);
    }, 2400);

    return () => {
      clearTimeout(toastTimeOut);
    };
  }, [showToast]);

  return (
    <>
      {showToast && (
        <div
          className={
            "position-fixed toast-wrap" +
            (width < 768 ? " toast-wrap-center" : "")
          }
        >
          <div
            className="toast-box py-3 px-3 px-md-5 d-flex align-items-center justify-content-between"
            style={{ backgroundColor: color }}
          >
            {icon}
            {props?.text || "Something went wrong!"}
          </div>
        </div>
      )}
    </>
  );
}

export default ToastMessage;
