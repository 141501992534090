import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { BsLightningFill } from "react-icons/bs";
import { FiChevronDown, FiClock } from "react-icons/fi";
// NAVIGATION
import SwiperCore, { Navigation } from "swiper";
import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import { getTimeSlotsByZone } from "../../api";
import { AppContext } from "../../context/AppContext";
import "./Deliverybar.css";

SwiperCore.use([Navigation]);

const Deliverybar = (props) => {
  const [showMoreTimeslots, setShowMoreTimeSlots] = useState(null);
  const [todayTimeSlots, setTodayTimeSlots] = useState([]);
  const [tomorrowTimeSlots, setTomorrowTimeSlots] = useState([]);
  const [selectedStandardTimeslot, setSelectedStandardTimeSlot] =
    useState("today");
  const [selectedExpressTimeslot, setSelectedExpressTimeSlot] =
    useState("today");
  const { latLong } = useContext(AppContext);

  const stdRef = useRef();
  const expRef = useRef();

  function hideOnClickOutside(element) {
    const outsideClickListener = (event) => {
      if (!element.contains(event.target)) {
        setShowMoreTimeSlots(null);
        removeClickListener();
      }
    };

    const removeClickListener = () => {
      document.removeEventListener(`click`, outsideClickListener);
    };

    document.addEventListener(`click`, outsideClickListener);
  }

  useEffect(() => {
    if (showMoreTimeslots) {
      showMoreTimeslots === "std"
        ? hideOnClickOutside(stdRef.current)
        : hideOnClickOutside(expRef.current);
    }
  }, [showMoreTimeslots]);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getTimeSlotsByZone(latLong?.zone?.id);
        setTodayTimeSlots(result.data.today);
        setTomorrowTimeSlots(result.data.tomorrow);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [latLong?.zone]);

  return (
    <div className="deliverybar container-fluid">
      {showMoreTimeslots && <div className="transparent-overlay"></div>}
      <div className="container deliverybar-content">
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center mr-2"
            style={{ position: "relative" }}
          >
            <FiClock color="white" strokeWidth="3px" />
            <span className="ml-1 mr-2">Standard Timeslots:</span>
            <div className="">
              <button
                className="d-flex align-items-center showmorebutton"
                onClick={(e) => {
                  setShowMoreTimeSlots("std");
                  e.stopPropagation();
                }}
              >
                <span className="timeslot">
                  {todayTimeSlots?.normalSlot &&
                  todayTimeSlots?.normalSlot.length > 0
                    ? `${todayTimeSlots?.normalSlot[0]?.delivery_from_time?.slice(
                        0,
                        -3
                      )} - ${todayTimeSlots?.normalSlot[0]?.delivery_to_time?.slice(
                        0,
                        -3
                      )}`
                    : `No timeslots available`}
                </span>
                <FiChevronDown color="white" strokeWidth="3px" />
              </button>
            </div>
            <div
              className={`slotlist ${showMoreTimeslots !== "std" && "d-none"}`}
              ref={stdRef}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center header">
                  <FiClock />
                  <div className="ml-1 mr-3">Scheduled Slots:</div>
                </div>
                <div>
                  <button
                    className={`slotbtn mr-3 ${
                      selectedStandardTimeslot === "today" && "active"
                    }`}
                    onClick={() => setSelectedStandardTimeSlot("today")}
                  >
                    Today
                  </button>
                  <button
                    className={`slotbtn ${
                      selectedStandardTimeslot === "tomorrow" && "active"
                    }`}
                    onClick={() => setSelectedStandardTimeSlot("tomorrow")}
                  >
                    Tomorrow
                  </button>
                </div>
              </div>
              <hr />
              {selectedStandardTimeslot === "today" && (
                <div className="timegrid">
                  {todayTimeSlots?.normalSlot &&
                  todayTimeSlots?.normalSlot.length > 0 ? (
                    todayTimeSlots?.normalSlot.map((v, idx) => {
                      return (
                        <div
                          className={`griditem ${idx === 0 && "active"}`}
                          key={v.id}
                        >{`${v.delivery_from_time?.slice(
                          0,
                          -3
                        )} - ${v.delivery_to_time?.slice(0, -3)}`}</div>
                      );
                    })
                  ) : (
                    <div className="griditem active">
                      No Available timeslots
                    </div>
                  )}
                </div>
              )}
              {selectedStandardTimeslot === "tomorrow" && (
                <div className="timegrid">
                  {tomorrowTimeSlots?.normalSlot &&
                  tomorrowTimeSlots?.normalSlot.length > 0 ? (
                    tomorrowTimeSlots?.normalSlot.map((v, idx) => {
                      return (
                        <div
                          className={`griditem ${idx === 0 && "active"}`}
                          key={v.id}
                        >{`${v.delivery_from_time?.slice(
                          0,
                          -3
                        )} - ${v.delivery_to_time?.slice(0, -3)}`}</div>
                      );
                    })
                  ) : (
                    <div className="griditem active">
                      No Available timeslots
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <hr className="divider mr-2" />
          <div
            className="d-flex align-items-center"
            style={{ position: "relative" }}
          >
            <BsLightningFill color="white" />
            <span className="ml-1 mr-2">Express Timeslots:</span>
            <div className="">
              <button
                className="d-flex align-items-center showmorebutton"
                onClick={(e) => {
                  setShowMoreTimeSlots("exp");
                  e.stopPropagation();
                }}
              >
                <span className="timeslot">
                  <span className="timeslot">
                    {todayTimeSlots?.expressSlot &&
                    todayTimeSlots?.expressSlot.length > 0
                      ? `${todayTimeSlots?.expressSlot[0]?.delivery_from_time?.slice(
                          0,
                          -3
                        )} - ${todayTimeSlots?.expressSlot[0]?.delivery_to_time?.slice(
                          0,
                          -3
                        )}`
                      : `No timeslots available`}
                  </span>
                </span>
                <FiChevronDown color="white" strokeWidth="3px" />
              </button>
            </div>
            <div
              className={`slotlist ${showMoreTimeslots !== "exp" && "d-none"}`}
              ref={expRef}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center header">
                  <BsLightningFill />
                  <div className="ml-1 mr-3">Express Slots:</div>
                </div>
                <div>
                  <button
                    className={`slotbtn mr-3 ${
                      selectedExpressTimeslot === "today" && "active"
                    }`}
                    onClick={() => setSelectedExpressTimeSlot("today")}
                  >
                    Today
                  </button>
                  <button
                    className={`slotbtn ${
                      selectedExpressTimeslot === "tomorrow" && "active"
                    }`}
                    onClick={() => setSelectedExpressTimeSlot("tomorrow")}
                  >
                    Tomorrow
                  </button>
                </div>
              </div>
              <hr />
              {selectedExpressTimeslot === "today" && (
                <div className="timegrid">
                  {todayTimeSlots?.expressSlot &&
                  todayTimeSlots?.expressSlot.length > 0 ? (
                    todayTimeSlots?.expressSlot.map((v, idx) => {
                      return (
                        <div
                          className={`griditem ${idx === 0 && "active"}`}
                          key={v.id}
                        >{`${v.delivery_from_time?.slice(
                          0,
                          -3
                        )} - ${v.delivery_to_time?.slice(0, -3)}`}</div>
                      );
                    })
                  ) : (
                    <div className="griditem active">
                      No Available timeslots
                    </div>
                  )}
                </div>
              )}
              {selectedExpressTimeslot === "tomorrow" && (
                <div className="timegrid">
                  {tomorrowTimeSlots?.expressSlot &&
                  tomorrowTimeSlots?.expressSlot.length > 0 ? (
                    tomorrowTimeSlots?.expressSlot.map((v, idx) => {
                      return (
                        <div
                          className={`griditem ${idx === 0 && "active"}`}
                          key={v.id}
                        >{`${v.delivery_from_time?.slice(
                          0,
                          -3
                        )} - ${v.delivery_to_time?.slice(0, -3)}`}</div>
                      );
                    })
                  ) : (
                    <div className="griditem active">
                      No Available timeslots
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deliverybar;
