import "bootstrap/dist/css/bootstrap.css";
import React, { useContext, useEffect, useState } from "react";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/swiper.scss";
import { AppContext } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import HomeBuilderRedirect from "../HomeBuilderRedirect/HomeBuilderRedirect";
import "./DealsView2.css";
import DealsV2MainItem from "./widgets/DealsV2MainItem/DealsV2MainItem";

const DealsView2 = (props) => {
  const [componentDesignData, setComponentDesignData] = useState({});
  const { appDesignData } = useContext(AppContext);
  const [mainItemData, setMainItemData] = useState({});
  const [sliderItemData, setSliderItemData] = useState([]);
  const { width } = useWindowSize();

  useEffect(() => {
    const fetchComponentData = async () => {
      const data = props?.componentDesignData;
      if (data) {
        setComponentDesignData({ ...data });
        const mainItem = data?.subTemplate?.widget.find(
          (widget) => widget.widgetCode === "DEALS_V2_MAIN_ITEM"
        );
        const sliderItems = data?.subTemplate?.widget.filter(
          (widget) => widget.widgetCode !== "DEALS_V2_MAIN_ITEM"
        );
        setMainItemData({ ...mainItem });
        setSliderItemData([...sliderItems]);
      }
    };
    fetchComponentData();
  }, [appDesignData]);

  return (
    <div className="deals-view-v2-wrapper">
      <div
        className="container"
        style={{
          background: `linear-gradient(93.58deg, ${componentDesignData?.subTemplate?.details.startGradient} 0%, ${componentDesignData?.subTemplate?.details.endGradient} 100%)`,
          padding: "1rem",
        }}
      >
        <h1 className="homepage_title" style={{ color: "white" }}>
          {componentDesignData?.subTemplate?.details?.title}
        </h1>
        <div className="container" style={{ background: "white" }}>
          <DealsV2MainItem
            {...mainItemData}
            subTitle1={componentDesignData?.subTemplate?.details.subTitle1}
            currentPrice={
              componentDesignData?.subTemplate?.details?.currentPrice
            }
            originalPrice={
              componentDesignData?.subTemplate?.details?.originalPrice
            }
          />
          <h1
            className="homepage_title"
            style={{ color: "black", marginBottom: "1rem" }}
          >
            {componentDesignData?.subTemplate?.details?.title2}
          </h1>
          <div className="d-flex justify-content-around align-items-center">
            {sliderItemData.map((item, index) => {
              return (
                <HomeBuilderRedirect
                  widget={item}
                  key={item._id}
                  styles={{ marginBottom: "2rem" }}
                >
                  <img
                    style={{ width: "82px" }}
                    src={
                      width >= 445
                        ? item?.details.imageUrlWeb
                          ? item?.details.imageUrlWeb
                          : item?.details.imageUrl
                        : item?.details.imageUrl
                    }
                    alt={item?.details.name}
                  />
                </HomeBuilderRedirect>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealsView2;
