import React, { useState } from "react";
import ProductGrid from "../../components/ProductGrid";
import "./favourites.css";
import EmptyData from "../../components/EmptyData";
import emptyFavs from "../../assets/images/favs-empty.svg";
import Rating from "../../components/Rating";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useLocation } from "react-router-dom";

const Favourites = () => {
  const { wishlistData } = useContext(AppContext);
  const location = useLocation();
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="mb-2 justify-content-center">
        {wishlistData?.length === 0 ? (
          <div className="empty-products">
            <EmptyData
              picture={emptyFavs}
              heading={"No Favourites"}
              description={"You haven't added any item to the list."}
            />
          </div>
        ) : (
          <ProductGrid
            data={wishlistData}
            type={location?.pathname === "/favourites" ? "nomenu" : "menu"}
          />
        )}
      </div>
      <Rating show={show} setShow={setShow} />
    </>
  );
};

export default Favourites;
