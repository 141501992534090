import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdPlayArrow } from "react-icons/md";
import { FiChevronRight } from "react-icons/fi";
import "./Breadcrumbs.css";

const Breadcrumbs = ({ breadcrumb = [], className }) => {
  return (
    <div className={className}>
      <div className="breadcrumbs" style={{ background: "none" }}>
        {breadcrumb?.length !== 0 && (
          <div className="breadcrumb-container d-flex px-2 py-1">
            {breadcrumb.map((link, i) => {
              return (
                <div className="link d-flex justify-content-center" key={i}>
                  {breadcrumb?.length !== i + 1 ? (
                    <Link
                      className={`text-regular-15 max-35vw ellipsis ${
                        i + 1 === breadcrumb.length ? "text-orange" : ""
                      }`}
                      to={link.path}
                    >
                      {link.title}
                      {i + 1 !== breadcrumb.length && (
                        <div className="arrow d-flex justify-content-center">
                          <FiChevronRight size="15px" />
                        </div>
                      )}
                    </Link>
                  ) : (
                    <div
                      className={`text-regular-15 max-35vw ellipsis ${
                        i + 1 === breadcrumb.length ? "text-orange" : ""
                      }`}
                    >
                      {link.title}
                      {i + 1 !== breadcrumb.length && (
                        <div className="arrow d-flex justify-content-center">
                          <FiChevronRight size="15px" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Breadcrumbs;
