import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getOrder, editOrder, getCouponInfo, getZoneById } from "../../api";
import ItemListing from "../../components/ItemListing";
import { AppContext } from "../../context/AppContext";
import { EditOrderLoader } from "../../components/SkeletonLoader";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import { BsInfoCircle } from "react-icons/bs";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ButtonSpinner from "../../components/ButtonSpinner";
import {
  minimumPrice,
  minimumPriceForFreeShipping,
} from "../../constants/storeMinimum";

function OrderEdit(props) {
  const { currentStore, setToastData, setShowToast, setBreadcrumbs } =
    useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState();
  const [orderData, setOrderData] = useState([]);
  const [orderItems, setOrderItems] = useState();
  const [orderItemData, setOrderItemData] = useState([]);
  const [orderItemDataEdit, setOrderItemDataEdit] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [couponCodeMinimumCartValue, setCouponCodeMinimumCartValue] =
    useState(0);
  const [currentStorePrice, setCurrentStorePrice] = useState(null);
  let originalArray = [];

  let history = useHistory();

  const checkCouponCodeLimit = () => {
    if (
      orderData?.coupon_code !== "" &&
      orderData?.coupon_code !== null &&
      orderData?.coupon_code !== undefined
    ) {
      getCouponInfo(orderData?.coupon_code, currentStore?.store_code)
        .then((res) => {
          setCouponCodeMinimumCartValue(res[0]?.minCartValue);
        })
        .catch((e) => {
          console.log("err", e);
        });
    }
  };

  useEffect(() => {
    checkCouponCodeLimit();
  }, [orderData]);

  function handleConfirmEdit() {
    let isChange = false;
    if (orderItems?.length === orderItemData?.length) {
      let length = orderItems?.length;
      orderItems?.map((item) => {
        if (item.qty === 0) {
          length -= 1;
        }
      });
      if (length === 0) {
        setShowToast(true);
        setToastData({
          type: "error",
          text: "Atleast one item should be in the List",
        });
        return 0;
      }
    }

    orderItems?.map((i) => {
      orderData?.items.map((j) => {
        if (j.sku === i.sku && parseInt(j.qty_ordered) !== parseInt(i.qty)) {
          isChange = true;
        }
      });
    });

    if (subTotal + taxTotal < currentStorePrice?.min_order_value) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: `Minimum Cart Value should be AED ${currentStorePrice?.min_order_value}`,
      });
      return 0;
    }

    if (
      couponCodeMinimumCartValue > 0 &&
      subTotal + taxTotal < couponCodeMinimumCartValue
    ) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: `Orders below minimum value of AED ${couponCodeMinimumCartValue} are not allowed.`,
      });
      return 0;
    }

    const payload = {
      sales_incremental_id: orderData.increment_id,
      items: orderItems,
      order_kind: "edit",
      store_id: currentStore.store_id,
    };
    if (orderItems && isChange) {
      setButtonIsLoading(true);
      editOrder(payload)
        .then(() => {
          window.gtag("event", "edit_order_success");
          history.push("/orderdetails/" + props?.match?.params?.orderId);
          setShowToast(true);
          setToastData({
            type: "success",
            text: "Your order was edited successfully!",
          });
        })
        .catch((e) => {
          window.gtag("event", "edit_order_failure");
          setShowToast(true);
          setToastData({
            type: "error",
            text: e?.message,
          });
        })
        .finally(() => setButtonIsLoading(false));
    } else {
      window.gtag("event", "edit_order_failure");
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Order was not edited",
      });
    }
  }

  const fixTwo = (x) => {
    let fix = Math.round(x * 100) / 100;
    return fix;
  };
  function handleSubTotal() {
    let mSubTotal = 0;
    let mTaxTotal = 0;
    orderItemDataEdit?.map((item) => {
      mSubTotal += fixTwo(item?.qty_ordered * item?.price);
      mTaxTotal += fixTwo(
        fixTwo(fixTwo(item?.qty_ordered * item?.price) * item?.tax_percent) /
          100
      );
    });

    setSubTotal(mSubTotal);
    setTaxTotal(mTaxTotal);
  }

  useEffect(() => {
    getOrder(props?.match?.params?.orderId).then(
      (res) => {
        setOrderData(res);
        getZoneById(res?.shipping_address?.zone_id)
          .then((result) => {
            setCurrentStorePrice(result?.data?.zone_order);
          })
          .catch((error) => {
            console.log(error);
          });
        let newArray = [];
        res?.items.map((i) => {
          newArray.push({
            sku: i.sku,
            qty_ordered: i.qty_ordered,
            price: i?.price,
            tax_percent: i?.tax_percent,
          });
        });
        setOrderItemData(res?.items);
        setOrderItemDataEdit(newArray);
        setIsLoading(false);
      },
      [props?.match?.params?.orderId]
    );

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setBreadcrumbs([
      { title: "Home", path: "/" },
      { title: "Orders", path: "/account/orders" },
      {
        title: "Order details",
        path: `/orderdetails/${props?.match?.params?.orderId}`,
      },
      { title: "Edit order" },
    ]);
  }, []);

  useEffect(() => {
    handleSubTotal();
  }, [orderItemDataEdit]);

  // useEffect(() => {}, [isDelete]);
  // useEffect(() => {}, [orderData]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        {`Order below AED ${currentStorePrice?.free_ship_value} are charged with a delivery charge of AED ${currentStorePrice?.shipping_charges}`}
      </Popover.Content>
    </Popover>
  );

  useDocumentTitle("Edit Order - Nesto Online Shopping");
  return isLoading ? (
    <EditOrderLoader />
  ) : (
    <div className="order-edit-page py-2">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <ItemListing
              isDelete={isDelete}
              setIsDelete={setIsDelete}
              data={orderItemData}
              setOrderItems={setOrderItems}
              orderItems={orderItems}
              setOrderItemData={setOrderItemData}
              setOrderItemDataEdit={setOrderItemDataEdit}
              orderItemDataEdit={orderItemDataEdit}
              orderData={orderData}
              type="orderedit"
            />
          </div>
          <div className="col-md-4">
            <div className="border-line-all container-radius">
              <p className="m-0 border-line p-3 faded-text text-center font-weight-bold">
                ORDER SUMMARY
              </p>
              <div className="p-4 border-line">
                <div className="d-flex justify-content-between mb-2">
                  <p className="m-0 paragraph-info">
                    {`Shipping Charges `}
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="bottom"
                      overlay={popover}
                    >
                      <BsInfoCircle />
                    </OverlayTrigger>
                  </p>

                  <p className="m-0 paragraph-info font-weight-bold">
                    AED{" "}
                    {`${parseFloat(orderData?.shipping_incl_tax).toFixed(2)}`}
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p className="m-0 paragraph-info">Sub Total</p>
                  <p className="m-0 paragraph-info font-weight-bold">
                    AED {subTotal.toFixed(2)}
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p className="m-0 paragraph-info">Discount</p>
                  <p className="m-0 paragraph-info font-weight-bold">
                    AED {parseInt(orderData?.base_discount_amount)?.toFixed(2)}
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p className="m-0 paragraph-info">Tax</p>
                  <p className="m-0 paragraph-info font-weight-bold">{`AED ${parseFloat(
                    taxTotal
                  ).toFixed(2)}`}</p>
                </div>
              </div>
              <div className="p-4">
                <div className="d-flex justify-content-between">
                  <p className="bold-label m-0 d-flex flex-column">
                    Grand Total<span className="small">VAT included</span>
                  </p>
                  <p className="bold-label m-0">
                    AED {parseFloat(orderData.total_due).toFixed(2)}
                  </p>
                </div>
                <button
                  onClick={handleConfirmEdit}
                  className="w-100 primary-button rounded-button my-3 p-3 click-anim"
                >
                  <ButtonSpinner
                    buttonText={"Confirm Changes"}
                    isLoading={buttonIsLoading}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderEdit;
