import React, { useState, createContext, useEffect } from "react";
import { firebase } from "../api/config";

export const FlagsContext = createContext({});
const remoteConfig = firebase.remoteConfig();

// For development only
remoteConfig.settings = {
  minimumFetchIntervalMillis: 10000,
};

const FlagsProvider = ({ defaults, children }) => {
  const [flags, setFlags] = useState(defaults);

  useEffect(() => {
    remoteConfig.defaultConfig = defaults;
    remoteConfig
      .fetchAndActivate()
      .then((activated) => {
        return remoteConfig.getAll();
      })
      .then((remoteFlags) => {
        const newFlags = {
          ...flags,
        };
        for (const [key, config] of Object.entries(remoteFlags)) {
          newFlags[key] = config.asString();
        }
        setFlags(newFlags);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
  );
};

export default FlagsProvider;
