import React, { useState, useEffect, useContext } from "react";
import CustomStepper from "../../components/CustomStepper/CustomStepper";
import OrderItem from "../../components/OrderItem/OrderItem";
import StatusActionCard from "../../components/StatusActionCard/StatusActionCard";
import OrderStatusData from "../../utils/OrderStatusData";
import "./OrderDetails.css";
import { useHistory } from "react-router-dom";
import ModalPopup from "../../components/ModalPopup/ModalPopup";
import { getOrder } from "../../api";
import moment from "moment";
import { OrderDetailsLoader } from "../../components/SkeletonLoader";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { GoCalendar } from "react-icons/go";
import { AiOutlineClockCircle, AiOutlineDownload } from "react-icons/ai";
import { FiPhone, FiUser } from "react-icons/fi";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import OrderItemView from "../../components/OrderItemView";
import { getAddressString } from "../../utils/getAddressString";
const OrderDetails = (props) => {
  const { setBreadcrumbs, setShowToast, setToastData } = useContext(AppContext);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setBreadcrumbs([
      { title: "Home", path: "/" },
      { title: "Orders", path: "/account/orders" },
      { title: "Order details" },
    ]);
  }, []);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showReturn, setShowReturn] = useState(true);
  const { checkedItems, setCheckedItems } = useContext(AppContext);
  const [refundAmount, setRefundAmount] = useState(0);
  const [orderLoading, setOrderLoading] = useState(false);
  // const orderData?.status = props?.location?.state?.status || "success";
  // const [orderList, setOrderList] = useState([]);
  const [orderData, setOrderData] = useState({});
  useEffect(() => {
    if (props?.match?.params?.orderId) {
      setOrderLoading(true);
      getOrder(props?.match?.params?.orderId)
        .then((res) => {
          setOrderData(res);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setOrderLoading(false);
        });
    }
  }, [props?.match?.params?.orderId]);
  const normalSteps = [
    orderData?.status?.toLowerCase() === "processing"
      ? "Processing"
      : "Pending",
    "Picking",
    "Packing",
    "In Transit",
    "Delivered",
  ];
  const returnSteps = [
    "Pending",
    "Picking",
    "Packing",
    "In Transit",
    "Delivered",
    "Return Processing",
    "In Transit",
    "Returned",
  ];
  useEffect(() => {
    setCheckedItems([]);
  }, []);
  const handleReturnOrReplace = (callType) => {
    if (checkedItems?.length === 0) {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Select atleast one item to return",
      });
      return 0;
    }
    setShow(false);
    history.push({
      pathname: `/confirmorderaction/${orderData?.entity_id}/${callType}`,
      state: { checkedItems: checkedItems, refundAmount: refundAmount },
    });
  };

  const StatusCardViewer = ({ status, itemData }) => {
    switch (status) {
      case "complete":
      case "delivered":
        return (
          <>
            <StatusActionCard type="inaam" data={itemData} />
            {moment(moment().format("yyyy-MM-DD")).diff(
              moment
                .utc(itemData?.shipping_address?.delivery_day)
                .local()
                .format("yyyy-MM-DD"),
              "days"
            ) <= 7 && (
              <StatusActionCard
                type="returnorreplace"
                onClick={() => {
                  setCheckedItems([]);
                  setShow(true);
                  setShowReturn(true);
                }}
              />
            )}
          </>
        );

      case "processing":
      case "pending":
      case "picking_initiated":
        return (
          <>
            <StatusActionCard type="delivery" data={itemData} />
            <StatusActionCard type="inaam" data={itemData} />
            <StatusActionCard
              type="editorder"
              onClick={() => {
                window.gtag("event", "edit_order");
                history.push(`/editorder/${props?.match?.params?.orderId}`);
              }}
            />
            <StatusActionCard
              type="cancel"
              onClick={() => {
                history.push(
                  `/confirmorderaction/${orderData?.entity_id}/cancelorder/`
                );
              }}
            />
          </>
        );
      case "picking_completed":
      case "packing_initiated":
        return (
          <>
            <StatusActionCard type="delivery" data={itemData} />
            <StatusActionCard type="inaam" data={itemData} />
            <StatusActionCard
              type="cancel"
              onClick={() => {
                history.push(
                  `/confirmorderaction/${orderData?.entity_id}/cancelorder/`
                );
              }}
            />
          </>
        );
      case "packing_completed":
      case "ready_for_delivery":
      case "out_for_delivery":
        return (
          <>
            <StatusActionCard type="delivery" data={itemData} />
            <StatusActionCard type="inaam" data={itemData} />
          </>
        );
      default:
        return <></>;
    }
  };

  const checkHandler = (e, newItem) => {
    if (e.target.checked) {
      setRefundAmount(
        parseFloat(refundAmount) + parseFloat(newItem?.base_price_incl_tax)
      );
      setCheckedItems([
        ...checkedItems,
        {
          id: newItem?.item_id,
          name: newItem?.name,
          quantity: parseInt(newItem?.qty_ordered),
        },
      ]);
    } else {
      setRefundAmount(
        parseFloat(refundAmount) - parseFloat(newItem?.base_price_incl_tax)
      );
      setCheckedItems(
        checkedItems?.filter((item) => item.id !== newItem.item_id)
      );
    }
  };
  // console.log(checkedItems);

  const handleItemShow = (show) => {
    setShow(show);
    setShowReturn(false);
  };
  useDocumentTitle("Order Details - Nesto Online Shopping");

  return (
    <div className="container my-2 py-2">
      {orderLoading ? (
        <OrderDetailsLoader />
      ) : (
        <>
          {!orderData?.status ? (
            <div>Order Details Not Found</div>
          ) : (
            <>
              <div className="col-md-12">
                {orderData?.status && (
                  <CustomStepper
                    step={
                      OrderStatusData(orderData?.status?.toLowerCase())?.step
                    }
                  />
                )}
              </div>
              <div className="row justify-content-between">
                <div className="col status-container px-lg-5 px-3">
                  <div className="mb-5">
                    <OrderItem
                      itemData={orderData}
                      status={orderData?.status?.toLowerCase()}
                      handleItemShow={handleItemShow}
                      type="inside"
                    />
                  </div>
                  <div className="row justify-content-between">
                    <StatusCardViewer
                      status={orderData?.status?.toLowerCase()}
                      itemData={orderData}
                    />
                  </div>
                  <div className="mt-5 justify-content-between">
                    <div className="p-2 address-view-header">
                      Payment Method
                    </div>
                    <div className="p-2 my-2">
                      {orderData?.payment?.additional_information?.method_title}
                    </div>
                  </div>
                  {orderData?.delivery_notes && (
                    <div className="justify-content-between">
                      <div className="p-2 address-view-header">
                        Delivery Notes
                      </div>
                      <div className="p-2 my-2">
                        {orderData?.delivery_notes}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4 address-view-container px-0">
                  <AddressView orderData={orderData} />
                </div>
              </div>
              <ModalPopup show={show} setShow={setShow}>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    <div className="fs-16 px-4 faded-color">
                      {showReturn ? "Select the items to be returned" : "Items"}
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <div className="container">
                  <div className="mx-lg-4 my-4">
                    <div className="p-0">
                      {orderData?.items?.map((item, index) => {
                        let isChecked;
                        const tempItem = checkedItems?.find(
                          (checkedItem) => checkedItem.id == item.item_id
                        );
                        tempItem?.id ? (isChecked = true) : (isChecked = false);
                        return (
                          <div key={index} className="d-flex my-2">
                            {showReturn && (
                              <div className="my-auto">
                                <input
                                  type="checkbox"
                                  className="mx-2"
                                  id={item.id}
                                  style={{ width: "25px", height: "25px" }}
                                  checked={isChecked}
                                  onClick={(e) => checkHandler(e, item)}
                                />
                              </div>
                            )}
                            <OrderItemView
                              checkedItems={checkedItems}
                              setCheckedItems={setCheckedItems}
                              item={item}
                              view="modal"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {showReturn && (
                    <>
                      <hr />
                      <div className="d-flex justify-content-around">
                        <StatusActionCard
                          type="returnorreplace"
                          text1="I want to"
                          text2={
                            "Return " +
                            (checkedItems?.length > 0
                              ? checkedItems.length + " "
                              : " ") +
                            "Items"
                          }
                          onClick={() => handleReturnOrReplace("return")}
                        />
                      </div>
                    </>
                  )}
                </div>
              </ModalPopup>
            </>
          )}
        </>
      )}
    </div>
  );
};

const AddressView = (props) => {
  return (
    <>
      <div className="address-view-header py-2 px-4">Delivery Address</div>
      <div className="p-4">
        <div className="address-view-text">
          <HiOutlineLocationMarker className="mr-4" />
          {getAddressString(props?.orderData.shipping_address)}
        </div>
        <div className="address-view-text">
          <FiUser className="mr-4" />
          {`${props?.orderData?.shipping_address?.firstname} ${props?.orderData?.shipping_address?.lastname}`}
        </div>
        <div className="address-view-text">
          <FiPhone className="mr-4" />
          {props?.orderData?.shipping_address?.telephone}
        </div>
      </div>
      {props?.orderData?.status?.toLowerCase() === "payment_failed" ||
      props?.orderData?.status?.toLowerCase() === "cancelled" ||
      props?.orderData?.status?.toLowerCase() === "closed" ||
      props?.orderData?.status?.toLowerCase() === "fraud" ||
      props?.orderData?.status?.toLowerCase() === "canceled" ? (
        <></>
      ) : (
        <>
          <div className="address-view-header py-2 px-4">Delivery Time</div>
          <div className="p-4">
            <div className="address-view-text">
              <GoCalendar className="mr-4" />
              {moment(props?.orderData?.shipping_address?.delivery_day).format(
                "LL"
              )}
            </div>
            <div className="address-view-text">
              <AiOutlineClockCircle className="mr-4" />

              {`${moment(
                `${props?.orderData?.shipping_address?.delivery_hours_from}.${props?.orderData?.shipping_address?.delivery_minutes_from}`,
                ["HH.mm"]
              ).format("hh:mm a")} - ${moment(
                `${props?.orderData?.shipping_address?.delivery_hours_to}.${props?.orderData?.shipping_address?.delivery_minutes_to}`,
                ["HH.mm"]
              ).format("hh:mm a")}`}
            </div>
          </div>
        </>
      )}

      <div className="address-view-header py-2 px-4">Order Information</div>
      <div className="p-4 address-view-order">
        <div className="row justify-content-between my-1 mx-2">
          <div className="order-title">Products</div>
          <div className="order-value">
            {props?.orderData?.items?.length === 1
              ? "1 item"
              : props?.orderData?.items?.length + " items"}
          </div>
        </div>
        <div className="row justify-content-between my-1 mx-2">
          <div className="order-title">Price</div>
          <div className="order-value">
            {props?.orderData?.base_currency_code +
              " " +
              (
                parseFloat(props?.orderData?.subtotal) +
                parseFloat(props?.orderData?.tax_amount)
              ).toFixed(2)}
          </div>
        </div>
        <div className="row justify-content-between my-1 mx-2">
          <div className="order-title">Shipping Fee</div>
          <div className="order-value">
            {props?.orderData?.base_currency_code +
              " " +
              parseFloat(props?.orderData?.base_shipping_amount).toFixed(2)}
          </div>
        </div>
        <div className="row justify-content-between my-1 mx-2">
          <div className="order-title">Discounts</div>
          <div className="order-value">
            {props?.orderData?.base_currency_code +
              " " +
              parseFloat(props?.orderData?.base_discount_amount).toFixed(2)}
            {/* {"-" + props.discount + " AED"} */}
          </div>
        </div>
        <div className="row justify-content-between my-1 mx-2">
          <div className="order-total-title">Total Price</div>
          <div className="order-total-value">
            {props?.orderData?.base_currency_code +
              " " +
              parseFloat(props?.orderData?.base_grand_total).toFixed(2)}
            {/* {props.price + props.shippingCharge - props.discount + " AED"} */}
          </div>
        </div>
        {props?.orderData?.invoiceUrl !== "" && (
          <div className="d-flex justify-content-center pt-2">
            <button
              className="btn btn-success"
              onClick={() => {
                window.location.href = props?.orderData?.invoiceUrl;
              }}
            >
              {"Download Tax Invoice"}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderDetails;
