import "bootstrap/dist/css/bootstrap.css";
import React, { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import useWindowSize from "../../../hooks/useWindowSize";
import HomeBuilderRedirect from "../../HomeBuilderRedirect/HomeBuilderRedirect";
import "./OfferCard.css";

const OfferCard = ({
  details: {
    title,
    imageUrl,
    imageUrlWeb,
    discountLabel,
    backgroundColor,
    redirectType,
    itemCode,
    titleFontSize,
    discountLabelFontSize,
  },
}) => {
  const { width } = useWindowSize();
  const { getScaledFontSize } = useContext(AppContext);

  return (
    <div
      className="d-inline-flex flex-column justify-content-center align-items-around rounded OfferCard"
      style={{
        background: backgroundColor,
        padding: width > 768 ? "0.875rem" : "none",
        minWidth: width > 768 ? "200px" : "none",
      }}
    >
      <HomeBuilderRedirect
        classes="d-inline-flex flex-column justify-content-center align-items-around rounded OfferCard"
        widget={{ details: { redirectType, itemCode } }}
      >
        <img
          src={width >= 445 ? (imageUrlWeb ? imageUrlWeb : imageUrl) : imageUrl}
          className="OfferCard__productImage"
          alt="produt"
          // style={{ height: "120px", width: "auto" }}
        />
        <div
          className={
            width > 425
              ? "d-flex flex-column align-items-center justify-content-center"
              : "d-flex flex-column align-items-start justify-content-center"
          }
        >
          <div
            className="mb-1 OfferCard__title"
            style={{
              textAlign: width < 425 ? "left" : "center",
              fontSize: `${getScaledFontSize(titleFontSize, 1.2)}px`,
            }}
          >
            {title}
          </div>
          <div
            className="mb-2 OfferCard__offer"
            style={{
              textAlign: width < 425 ? "left" : "center",
              fontSize: `${getScaledFontSize(discountLabelFontSize, 1.2)}px`,
            }}
          >
            {discountLabel}
          </div>
        </div>
        {width > 768 && (
          <div
            className="d-flex add-cart-button text-center justify-content-around align-items-center false"
            style={{
              backgroundColor: "#2D9140",
              color: "white",
              fontWeight: "700",
              fontSize: "0.875rem",
              border: "1px solid transparent",
            }}
          >
            <div className="w-100 p-2">Shop Now</div>
          </div>
        )}
      </HomeBuilderRedirect>
    </div>
  );
};

export default OfferCard;
