import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy-page-layout">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="privacy-link-layout">
        <Link to="/" className="privacy-link">
          X
        </Link>
      </div>
      <h1 className="privacy-heading">Privacy Policy</h1>
      <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">1. Introduction</h2>
        </div>
        <ul>
          <li>
            Nesto Hypermarket LLC (including our affiliates and subsidiaries) are committed to protect the privacy and security of the personal information shared to us by our customers. When you shop with us online or in-store or communicate with us, we collect and create personal data about you. This Privacy Policy details how we collect, use, process, and disclose information about you.
          </li>
          <li>
            This Privacy Policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.
          </li>
          <li>
            Your access of our services indicates that you have read, understood and unconditionally accepted the provisions in this Privacy Policy, and such acceptance constitutes your legally binding acceptance of all terms and conditions set out in this Privacy Policy.
          </li>
          <li>
			We reserve the right to amend this Privacy Policy at any time to comply with regulatory requirements or in line with our business requirement. Such amends will be made available for your review in our website, mobile applications or any other platform that has been made available for you to access our services. You are requested to familiarize yourself with the Privacy Policy periodically. Your continued use of the services offered following the posting of any such changes will constitute your acceptance of the revised Privacy Policy.
          </li>
        </ul>
      </div>
      <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">2. Information Collection</h2>
        </div>
        <p>
			Data maybe collected by ourselves or vide engaged third party services or tools. We may gather the following types of data from your interaction with us.
        </p>
        <ol>
          <li>
            Personally identifiable information and contact data provided by you at the time of voluntary registration for our loyalty program, “Inaam” or marketing events such as (but not limited to) name, email address, mailing address, telephone number, date of birth, gender and nationality. Inaam has been developed to provide rewards for you. Inaam also provides you a summary of the transactions you complete with us.
          </li>
          <li>
            Purchase information and transaction details such as items, bill amounts, mode of payments, or payment card details. This may include when, where, what and how you purchased that product or service. It will also include delivery details and the benefits accrued by you from the transaction.
          </li>
		  <li>
			Data is generated every time you interact with us through our stores, websites, or mobile apps. Interaction data is collected from you as and when you access or use our services. Interaction data is information about how you interact with our products and services, namely what you click on and interact with on our sites and app, or products in-store.
		  </li>
		  <li>
			We may collect marketing data based on your marketing preferences and your interaction with our marketing conducted online and offline.
		  </li>
		  <li>
			We may collect your location information to provide you better information about our local stores or to facilitate deliveries of the orders made by you with us.
		  </li>
		  <li>
			We may collect technical data about the device you use to access our website and mobile application such as device name, operating system, Advertising ID, IP address, sign-in data, plugins, and frequency of access or use.
		  </li>
        </ol>
      </div>
      <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">3. Use Of Information</h2>
        </div>
        <p>
			To serve you better as a customer, personal information submitted to us will be used for the purposes specified in this policy.
		</p>
        <ol>
          <li>To fulfill the services or products as requested by you</li>
          <li>
            To carry out market research and internal research for product improvement and enhancement of our information technology systems
          </li>
          <li>
            To provide further information to you about other services or products which we consider may be of interest to you;
          </li>
          <li>
            For future marketing, promotional and publicity purposes, including to carry out direct marketing, market research and surveys;
          </li>
          <li>For ensuring that you are shown the information that is most relevant to you and your interests</li>
		  <li>To analyze traffic in our online platforms and footfall in our physical outlets</li>
		  <li>To understand individual preferences, shopping habits, product popularity and market trends</li>
		  <li>To combine and compare with other similar data to derive patterns and conclusions.</li>
		  <li>To prevent fraud or misuse of services.</li>
		  <li>For any other use that you authorize.</li>
        </ol>
      </div>
	  <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">4. AGGREGATED DATA</h2>
        </div>
        <p>
			We try and remove personal data to make the data anonymous making you unidentifiable. At times, you identifiable information may be replaced with non-identifying information such as an ID number or a reference number. Such modified data is combined with other data form aggregated data which enables us to deduct patterns or customer behaviors.
		</p>
      </div>
      <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">5. Disclosure Of Your Personal Data</h2>
        </div>
        <p>
          We may share your personal information for the following purposes:
        </p>
        <ol>
          <li>
            any information that we gather about you (other than financial data
            which will be disclosed to the payment gateway service providers) to
            third parties for the purposes of providing you with additional
            services;
          </li>
		  <li>to facilitate a service or delivery of a product that has been ordered by you.</li>
		  <li>With specialist third parties for data analysis and to deduce business intelligence.</li>
          <li>
            aggregate tracking information and other information that does not
            personally identify you to third parties;
          </li>
          <li>
            your personal information to third parties, when we believe in good
            faith that it is required to do so by law;
          </li>
          <li>
            your personal information to third parties, provided we have your
            prior consent, which may be obtained at the time of collecting the
            information from you.
          </li>
        </ol>
        <p>
          We shall not share your Personal Information with any third party
          (other than our affiliates) unless you have provided your express
          consent. We maintain strict security standards and procedures with a
          view to preventing unauthorized access to your data by anyone,
          including our staff.
        </p>
        <ul>
          <li>
            We will seek your consent if we wish to use your personal
            information for purposes other than those you have agreed to
            previously.
          </li>
          <li>
            We will retain the personal information provided by you for as long as is reasonably necessary for the purposes listed in this Privacy Policy.
          </li>
        </ul>
      </div>
	  <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">6. SOCIAL MEDIA</h2>
        </div>
        <p>
          We maintain an active presence in social media to interact with our customers and inform them of our new services, products, and promotions. We may record your interaction with our social media accounts such as likes and comments to improve our services.
        </p>
		<p>
		  Your caution is requested while sharing personal information in social media or in the internet. Do not hesitate to contact us if you suspect that a party is misrepresenting or obtaining your information on our behalf. Always ensure that that the social media accounts, websites, or mobile applications that you engage with are our “verified” or “official” accounts/handles.
		</p>
		<p>
		  The social media platforms maintain their own privacy policies and data retention policies which you may be subject to. Such data may be used for profiling or targeted advertising.
		</p>
      </div>
      <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">7. Data Retention</h2>
        </div>
        <p>
          We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you. To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.
        </p>
      </div>
      <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">8. Data Security</h2>
        </div>
        <p>
          We have put in place physical security measures and digital/electronic safeguards to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. We audit the security measures in place periodically to ensure that your data is protected at all times. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
        </p>
      </div>
      <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">9. DATA COLLECTED FROM AND BY THIRD PARTIES</h2>
        </div>
        <p>
          We may gather personal data from other sources, such as specialist companies or media partners. Such specialist companies would have obtained your consent at the time when they collected data from you. We use this and our own data to better understand our customer.
        </p>
		<p>
          Other than third parties that have been engaged by us contractually to process or analyze your data, we are not responsible for the data collection practices, the privacy policies or the content of links, advertisements, websites, or applications that have been hosted in our platforms by third parties or at the request of third parties. Such hosting has been done by us in good faith and your data will be collected and processed by such third parties in line with their privacy policy. You are requested to make yourselves aware with the privacy policy in place by such third parties.
        </p>
      </div>
      <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">10. COMMUNICATION PREFERENCE</h2>
        </div>
        <p>
          If our intended collection, use or disclosure of your personal information is outside the collection, use or disclosure parameters in this Privacy Policy, we will give you the option to:
        </p>
		<ol>
			<li>
				opt out and not receive certain services or participate in certain interactive areas; or
			</li>
			<li>
				opt in to agree to be contacted by us in relation to certain matters such as notification of new features or services, beta testing of our technical platforms or promotional activities.
			</li>
		</ol>
      </div>
      <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">11. Cookies</h2>
        </div>
        <p>
          Our online platforms such as websites and mobile applications uses cookies. A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server. If you wish to refuse to accept cookies, you may choose to disable it in your browser and/or delete cookies from your device storage. It is not mandatory to have cookies turned on to use the Website however, you may require cookies to access personalized or secure content on the Website. Some web pages may not function properly if the cookies are disabled.
        </p>
      </div>
      <div>
        <div className="privacy-title-border">
          <h2 className="privacy-title">12. RIGHTS TO YOUR DATA</h2>
        </div>
        <p>
          You have the right to see the personal data we hold about you. If you would like to see the data we hold about you, you can email us at
          <a href="mailto:support@nesto.shop">support@nesto.shop</a>
        </p>
		<p>With regards to your personal information, you have the following rights subject to applicable local laws.</p>
		You have the right to:
		<ul>
			<li>correct any inaccurate information</li>
			<li>delete any personal information</li>
			<li>restrict our use of personal information</li>
			<li>object to our use of your personal information;</li>
			<li>withdraw any consents you have provided for collection and use of your data.</li>
			<li>transfer your data to another third party</li>
			<li>lodge a complaint with your local data protection authority.</li>
		</ul>
      </div>
	  <div><p>Last updated on: August 19, 2022</p></div>
    </div>
  );
}
