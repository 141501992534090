import React from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import HomeBuilderRedirect from "../../../HomeBuilderRedirect/HomeBuilderRedirect";

const SquareGridItem = ({ widget, index }) => {
  const { width } = useWindowSize();
  return (
    <HomeBuilderRedirect widget={widget}>
      <div
        key={widget._id}
        className={`d-flex flex-column align-items-center justify-content-between grid-item ${
          index === 0 || index === 7 ? "Grid_cornerItems" : ""
        }`}
      >
        <div
          className="square-grid-image-wrapper outer_circle"
          style={{
            borderColor: `linear-gradient(${widget.details.borderStartGradient} 100%, ${widget.details.borderEndGradient} 100%)`,
            // width: "1rem",
            background: `linear-gradient(180deg, ${widget.details.startGradient} 0%, ${widget.details.endGradient} 100%)`,
          }}
        >
          <img
            className="grid-image"
            src={
              width >= 445
                ? widget.details.imageUrlWeb
                  ? widget.details.imageUrlWeb
                  : widget.details.imageUrl
                : widget.details.imageUrl
            }
            alt="category"
          />
        </div>
        <div
          className="grid-title square-grid-title"
          style={{ marginBottom: "1rem", textAlign: "center" }}
        >
          {widget.details.title}
        </div>
      </div>
    </HomeBuilderRedirect>
  );
};

export default SquareGridItem;
