import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
// NAVIGATION
import SwiperCore, { Navigation } from "swiper";
import "swiper/components/navigation/navigation.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import useWindowSize from "../../hooks/useWindowSize";
import compareWidgetsPosition from "../../utils/compareWidgetsPosition";
import "./GridView.css";
import OfferCard from "./OfferCard/OfferCard";

SwiperCore.use([Navigation]);
const GridView = ({ componentDesignData }) => {
  const { width } = useWindowSize();

  const calculateTimeLeft = () => {
    const difference = moment(
      componentDesignData?.subTemplate?.details?.endDate
    ).diff(moment());
    const duration = moment.duration(difference);
    const days = duration.asDays();
    return Math.floor(days);
  };

  return calculateTimeLeft() > 0 ? (
    <div className={width > 768 ? "container GridView" : "GridView"}>
      <div
        className={`container-fluid GridView_wrapper grid-view-swiper-${componentDesignData?.position}`}
        style={{
          paddingBottom: "2rem",
          background: `linear-gradient(${componentDesignData?.subTemplate?.details.startGradient} 100%, ${componentDesignData?.subTemplate?.details.endGradient} 100%)`,
        }}
      >
        {width > 768 ? (
          <div className="">
            <div
              className="row py-2 d-flex align-items-center justify-content-between"
              style={{ width: "100%", margin: "auto" }}
            >
              <h2 className="homepage_title" style={{ marginBottom: "0" }}>
                {componentDesignData?.subTemplate?.details?.title}
              </h2>
              <div
                style={{
                  background: "#52C41A",
                  marginLeft: "1rem",
                  borderRadius: "0.75rem",
                }}
                className="d-flex align-items-center justify-content-center p-2"
              >
                <AiOutlineClockCircle size={width <= 425 ? 15 : 20} />
                <div className="GridView__EndTime" style={{}}>
                  Ends in {calculateTimeLeft()} days
                </div>
              </div>
            </div>
            <div className="swipe-arrows">
              <div className="swipe-button-prev">
                <FiChevronLeft className="swipe-arrow" />
              </div>
              <div className="swipe-button-next">
                <FiChevronRight className="swipe-arrow" />
              </div>
              <Swiper
                spaceBetween={10}
                slidesPerView={"auto"}
                navigation={{
                  nextEl: `.grid-view-swiper-${componentDesignData?.position} .swipe-button-next`,
                  prevEl: `.grid-view-swiper-${componentDesignData?.position} .swipe-button-prev`,
                }}
                observer={true}
                observeParents={true}
                style={{ marginLeft: 0, marginRight: 0 }}
              >
                {componentDesignData?.subTemplate?.widget
                  .sort(compareWidgetsPosition)
                  .map((offer, index) => (
                    <SwiperSlide
                      key={"shopby-" + index}
                      style={{ flexShrink: "1" }}
                    >
                      <OfferCard {...offer} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        ) : (
          <div className="">
            <div
              className="row py-2 d-flex align-items-center justify-content-between"
              style={{ width: "100%", margin: "auto" }}
            >
              <h2 className="homepage_title" style={{ marginBottom: "0" }}>
                {componentDesignData?.subTemplate?.details?.title}
              </h2>
              <div
                style={{
                  background: "#52C41A",
                  marginLeft: "1rem",
                  borderRadius: "0.75rem",
                }}
                className="d-flex align-items-center justify-content-center p-2"
              >
                <AiOutlineClockCircle size={width <= 425 ? 15 : 20} />
                <div className="GridView__EndTime" style={{}}>
                  Ends in {calculateTimeLeft()} days
                </div>
              </div>
            </div>
            <div className="grid-wrapper-mobile">
              {componentDesignData?.subTemplate?.widget
                .sort(compareWidgetsPosition)
                .map((offer, index) => (
                  <OfferCard {...offer} key={offer._id} />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default GridView;
