import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import useWindowSize from "../../../hooks/useWindowSize";
import HomeBuilderRedirect from "../../HomeBuilderRedirect/HomeBuilderRedirect";

const TwoBannerImageV1 = ({ widget }) => {
  const { getScaledFontSize } = useContext(AppContext);
  const { width } = useWindowSize();
  return (
    <div
      className="d-flex align-items-center justify-content-between grid-wrapper-1"
      style={{
        background: `linear-gradient(${widget?.details?.startGradient} 100%, ${widget?.details?.endGradient} 100%)`,
      }}
    >
      <div className="offer-btn-wrapper d-flex flex-column align-items-start justify-content-around">
        <div
          className="offer-title"
          style={{
            fontSize: `${getScaledFontSize(
              widget?.details?.titleFontSize,
              2.2
            )}px`,
          }}
        >
          {widget?.details?.title}
        </div>
        <HomeBuilderRedirect widget={widget}>
          <button
            className="banner-button"
            style={{
              background: widget?.details?.buttonColor,
              color: widget?.details?.buttonFontColor,
            }}
          >
            {widget?.details?.buttonName}
          </button>
        </HomeBuilderRedirect>
      </div>
      <img
        className="banner-images"
        src={
          width >= 445
            ? widget?.details?.imageUrlWeb
              ? widget?.details?.imageUrlWeb
              : widget?.details?.imageUrl
            : widget?.details?.imageUrl
        }
        alt="fruits-and-vegetables"
      />
    </div>
  );
};

export default TwoBannerImageV1;
