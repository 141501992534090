import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { FiCheck } from "react-icons/fi";
import { BsChevronBarExpand } from "react-icons/bs";
import "./CustomStepper.css";

const CustomStepper = ({ step }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [progressHeight, setProgressHeight] = useState("auto");
  const progressWrap = useRef();
  function handleToggleProgress() {
    if (progressHeight === "50px" && isMobile) {
      setProgressHeight(`${progressWrap.current.scrollHeight + 20}px`);
      return;
    } else if (isMobile) {
      setProgressHeight(`${50}px`);
      return;
    }
    setProgressHeight("auto");
  }

  function handleResize() {
    if (window.innerWidth < 768) {
      setIsMobile(true);
      setProgressHeight(`${50}px`);
      return;
    }
    setIsMobile(false);
    setProgressHeight("auto");
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  let returnSteps = [
    { position: 4, status: "Delivered" },
    { position: 5, status: "Return Initiated" },
    { position: 6, status: "Return Collected" },
    { position: 7, status: "Returned" },
  ];
  let normalSteps = [
    { position: 0, status: "Pending" },
    { position: 1, status: "Picked" },
    { position: 2, status: "Packed" },
    { position: 3, status: "In Transit" },
    { position: 4, status: "Delivered" },
  ];
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (step > 4) {
      setSteps(returnSteps);
    } else {
      setSteps(normalSteps);
    }
  }, [step]);
  return (
    <div className="container mb-md-5 my-2">
      <div
        className={`progress-wrap-outer${isMobile ? " overflow-hidden" : ""}`}
        ref={progressWrap}
        style={{ height: progressHeight }}
      >
        <div className="progress-wrap w-100">
          {steps?.map((stepItem, index) => {
            return (
              <div
                key={stepItem?.status}
                className={
                  "progress-item " +
                  (stepItem?.position < step
                    ? "complete"
                    : stepItem?.position === step
                    ? "current"
                    : "")
                }
              >
                <FiCheck />
                <div className="progress-text">
                  {stepItem?.status}
                  <br />
                  <div className="progress-text-status"></div>
                </div>
              </div>
            );
          })}
          {/* <div className="progress-item complete">
            <FiCheck />
            <div className="progress-text">
              Ordered
              <br />
              <div className="progress-text-status">Picked</div>
            </div>
          </div>
          <div className="progress-item current next">
            <FiCheck />
            <div className="progress-text">
              Packed
              <br />
              <div className="progress-text-status"></div>
            </div>
          </div>
          <div className="progress-item">
            <FiCheck />
            <div className="progress-text">
              In Transit
              <br />
              <div className="progress-text-status"></div>
            </div>
          </div>
         */}
        </div>
      </div>
      {isMobile ? (
        <button className="progress-expand" onClick={handleToggleProgress}>
          <BsChevronBarExpand /> {progressHeight ==="50px"?"Expand for more info":"Collapse"}
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CustomStepper;
