import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import useWindowSize from "../../hooks/useWindowSize";
import "../../pages/HomepageNew/HomepageNew.css";
import compareWidgetsPosition from "../../utils/compareWidgetsPosition";
import HomeBuilderRedirect from "../HomeBuilderRedirect/HomeBuilderRedirect";
import "./Carousel.css";

const MainCarousel = ({ componentDesignData }) => {
  const { width } = useWindowSize();

  return (
    <div>
      <div className="carousel-wrapper">
        <Carousel style={{ margin: "auto" }}>
          {componentDesignData?.subTemplate?.widget
            ?.sort(compareWidgetsPosition)
            .map((widget) => (
              <Carousel.Item
                key={widget._id}
                interval={1500}
                style={{ cursor: "pointer" }}
              >
                <HomeBuilderRedirect widget={widget}>
                  <img
                    className="d-block w-100"
                    style={{
                      maxHeight: "280px",
                      maxWidth: "1366px",
                      objectFit: "contain",
                    }}
                    src={
                      width >= 445
                        ? widget?.details?.imageUrlWeb
                          ? widget?.details?.imageUrlWeb
                          : widget?.details?.imageUrl
                        : widget?.details?.imageUrl
                    }
                    alt="Carousel"
                  />
                </HomeBuilderRedirect>
              </Carousel.Item>
            ))}
        </Carousel>
      </div>
    </div>
  );
};

export default MainCarousel;
