import useWindowSize from "../../../hooks/useWindowSize";
import HomeBuilderRedirect from "../../HomeBuilderRedirect/HomeBuilderRedirect";

const NoMarginOnlyImage = ({ componentDesignData }) => {
  const { width } = useWindowSize();
  return (
    <div style={{ width: "100%", cursor: "pointer" }}>
      <HomeBuilderRedirect widget={componentDesignData?.subTemplate?.widget[0]}>
        <img
          style={{ width: "100%", maxHeight: "280px" }}
          src={
            width >= 445
              ? componentDesignData?.subTemplate?.widget[0].details.imageUrlWeb
                ? componentDesignData?.subTemplate?.widget[0].details
                    .imageUrlWeb
                : componentDesignData?.subTemplate?.widget[0].details.imageUrl
              : componentDesignData?.subTemplate?.widget[0].details.imageUrl
          }
          alt={componentDesignData?.subTemplate?.widget[0].details.name}
        />
      </HomeBuilderRedirect>
    </div>
  );
};

export default NoMarginOnlyImage;
