import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

export default function HomeBuilderRedirect({
  widget,
  children,
  classes,
  styles,
}) {
  const { getRedirectionUrl } = useContext(AppContext);

  if (widget?.details?.redirectType === "EXTERNAL_LINK") {
    return (
      <a
        href={widget?.details?.itemCode}
        target="_blank"
        rel="noopener noreferrer"
        className={classes}
        style={{ all: "unset", cursor: "pointer", ...styles }}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={getRedirectionUrl(
        widget?.details?.redirectType,
        widget?.details?.itemCode
      )}
      style={{ all: "unset", cursor: "pointer", ...styles }}
      className={classes}
    >
      {children}
    </Link>
  );
}
