import React, { useState } from "react";
import { useHistory } from "react-router";
import expandRight from "../../assets/images/expand-right.svg";
import successCircle from "../../assets/images/success-circle-green.svg";
import moment from "moment";
import "./StatusActionCard.css";
const StatusActionCard = props => {
  const actionCardType = {
    editorder: {
      classNames: "action-card-warning",
      text1: "Made a mistake?",
      text2: "Edit this order"
    },
    paymentIncomplete: {
      classNames: "action-card-failed",
      text1: "Payment Incomplete",
      text2: "Retry Payment"
    },

    cancel: {
      classNames: "action-card-failed",
      text1: "Having Problems?",
      text2: "Cancel This Order"
    },

    returnorreplace: {
      classNames: "action-card-failed",
      text1: "Made a mistake?",
      text2: "Return or Replace Items"
    },
    delivery: {
      classNames: "action-card-success",
      text1: "Delivery on",
      text2: props?.data?.shipping_address
        ? moment(props?.data?.shipping_address?.delivery_day).calendar(null, {
            lastDay: "[Yesterday]",
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            lastWeek: "[Last] dddd",
            nextWeek: "dddd",
            sameElse: "L"
          }) +
          " " +
          `${moment(
            `${props?.data?.shipping_address?.delivery_hours_from}.${props?.data?.shipping_address?.delivery_minutes_from}`,
            ["HH.mm"]
          ).format("hh:mm a")} - ${moment(
            `${props?.data?.shipping_address?.delivery_hours_to}.${props?.data?.shipping_address?.delivery_minutes_to}`,
            ["HH.mm"]
          ).format("hh:mm a")}`
        : "Delivery Time Not Available"
    },
    inaam: {
      classNames: "action-card-success",
      text1: "INAAM points earned",
      text2: props?.data?.base_subtotal
        ? parseFloat(props?.data?.base_subtotal / 5).toFixed(2) + " Points"
        : "Points Not Available"
    }
  };

  return (
    <div key={props.index} className={"col-md-6 mb-4"} onClick={props.onClick}>
      <div
        className={
          "action-card-container " + actionCardType[props.type].classNames
        }
      >
        {props.type === "editorder" ||
        props.type === "returnorreplace" ||
        props.type === "cancel" ||
        props.type === "paymentIncomplete" ? (
          <div className="row">
            <div className="col-10 align-self-center">
              <div className="action-card-text1">
                {props.text1 ? props.text1 : actionCardType[props.type].text1}
              </div>
              <div className="action-card-text2">
                {props.text2 ? props.text2 : actionCardType[props.type].text2}
              </div>
            </div>
            <div className="col-2 align-self-center">
              <img src={expandRight} className="action-card-icon" />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-2 align-self-center">
              <img src={successCircle} className="action-card-icon" />
            </div>
            <div className="col-10 align-self-center">
              <div className="action-card-text1">
                {actionCardType[props.type].text1}
              </div>
              <div className="action-card-text2">
                {actionCardType[props.type].text2}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusActionCard;
