export default function validate(values, type) {
  let emailFormat =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (type === "email") {
    if (!emailFormat.test(values)) {
      return false;
    } else return true;
  } else if (type === "tel") {
    if (values.length === 13) {
      return true;
    } else return false;
  }
}
