import "bootstrap/dist/css/bootstrap.css";
import React, { useContext, useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import { AppContext } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import compareWidgetsPosition from "../../utils/compareWidgetsPosition";
import HomeBuilderRedirect from "../HomeBuilderRedirect/HomeBuilderRedirect";
import "./DealsView.css";

const DealsView = ({ componentDesignData }) => {
  const [subTemplate, setSubTemplate] = useState({});
  const { width } = useWindowSize();
  const { getScaledFontSize } = useContext(AppContext);

  useEffect(() => {
    if (componentDesignData) {
      setSubTemplate(componentDesignData?.subTemplate);
    }
  }, [componentDesignData]);

  return (
    <div className={width > 768 ? "container" : ""}>
      <div
        className="container DealsView d-flex align-items-start justify-content-start"
        style={{
          background: `linear-gradient(129.77deg, ${subTemplate?.details?.startGradient} 17.92%, ${subTemplate?.details?.endGradient} 87.42%)`,
        }}
      >
        <div className="DealsView__comboDeal" style={{ cursor: "pointer" }}>
          <HomeBuilderRedirect
            widget={componentDesignData?.subTemplate?.widget[0]}
          >
            <h4 className="DealsView__title">{subTemplate?.details?.title}</h4>
            <img
              className="DealsView__comboImage"
              src={
                width >= 445
                  ? componentDesignData?.subTemplate?.widget[0]?.details
                      ?.imageUrlWeb
                    ? componentDesignData?.subTemplate?.widget[0]?.details
                        ?.imageUrlWeb
                    : componentDesignData?.subTemplate?.widget[0]?.details
                        ?.imageUrl
                  : componentDesignData?.subTemplate?.widget[0]?.details
                      ?.imageUrl
              }
              alt="combo-deal"
            />
            <div className="DealsView__subtitle">
              {subTemplate?.details?.subTitle1}
            </div>
            <div className="DealsView__title">
              {subTemplate?.details?.priceRange}
            </div>
          </HomeBuilderRedirect>
        </div>
        <div className="deals-view-slider">
          <h4
            className="DealsView__title deals-view-slider-title"
            style={{ marginLeft: "0.5rem" }}
          >
            {subTemplate?.details?.title2}
          </h4>
          <div className="swipe-arrows">
            <div className="swipe-button-prev">
              <FiChevronLeft className="swipe-arrow" />
            </div>
            <div className="swipe-button-next">
              <FiChevronRight className="swipe-arrow" />
            </div>
            <Swiper
              spaceBetween={width > 425 ? 2 : 0.5}
              // freeMode={true}
              slidesPerGroup={1}
              // breakpoints={{
              //   // when window width is >= 280px
              //   0: {
              //     slidesPerView: 2.2,
              //   },
              //   320: {
              //     slidesPerView: 2.3,
              //   },
              //   380: {
              //     slidesPerView: 2.8,
              //   },
              //   // when window width is >= 460px
              //   460: {
              //     slidesPerView: 3.5,
              //   },
              //   // when window width is >= 640px
              //   640: {
              //     slidesPerView: 4,
              //   },
              //   // when window width is >= 820px
              //   763: {
              //     slidesPerView: 3.5,
              //   },
              //   820: {
              //     slidesPerView: 4,
              //   },
              //   920: {
              //     slidesPerView: 4,
              //   },
              // }}
              slidesPerView={"auto"}
              navigation={{
                nextEl: ".deals-view-slider .swipe-button-next",
                prevEl: ".deals-view-slider .swipe-button-prev",
              }}
              observer={true}
              observeParents={true}
              style={{
                marginLeft: width < 776 ? 0 : "0.5rem",
                marginRight: width < 776 ? 0 : "0.5rem",
              }}
            >
              {componentDesignData?.subTemplate?.widget
                .sort(compareWidgetsPosition)
                .map(
                  (widget, index) =>
                    index !== 0 && (
                      <SwiperSlide
                        key={"deals-view-slider" + index}
                        style={{ flexShrink: "1" }}
                      >
                        <HomeBuilderRedirect widget={widget}>
                          <div
                            className="DealsView__slider-product d-inline-flex"
                            style={{
                              background: widget.details.backgroundColor,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={
                                widget.details.imageWebUrl ||
                                widget.details.imageUrl
                              }
                              className="dealsView__sliderImage"
                              alt="product"
                            />
                            <div className="d-flex flex-column align-items-start justify-content-between flex-grow-1">
                              <div
                                className="dealsView__productName"
                                style={{
                                  fontSize: `${getScaledFontSize(
                                    widget.details.titleFontSize,
                                    1.16
                                  )}px`,
                                }}
                              >
                                {widget.details.title}
                              </div>
                              <div
                                className="dealsView__productPrice"
                                style={{
                                  fontSize: `${getScaledFontSize(
                                    widget.details.priceFontSize,
                                    1.16
                                  )}px`,
                                }}
                              >
                                {widget.details.price}
                              </div>
                            </div>
                          </div>
                        </HomeBuilderRedirect>
                      </SwiperSlide>
                    )
                )}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealsView;
