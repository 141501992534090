import React from "react";
import "./StatusDisplay.css";
import Lottie from "react-lottie";
import { useHistory } from "react-router";
import Loader from "react-loader-spinner";
const StatusDisplay = ({
  animationData,
  heading,
  details,
  loading,
  showRetry,
  handlePaymentRetry,
  handleViewOrderDetails,
  showViewOrderDetails,
  loop
}) => {
  const history = useHistory();
  const defaultOptions = {
    autoplay: true,
    loop: loop,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="status-display">
      <div className="col-12">
        <Lottie options={defaultOptions} height={250} width={250} />
      </div>
      <p className="status-heading">{heading}</p>
      <p className="status-details">{details}</p>
      <div className="d-flex justify-content-around">
        {showRetry && (
          <button
            className="btn btn-success mx-2 p-2"
            onClick={handlePaymentRetry}
          >
            {loading ? (
              <Loader type="TailSpin" color={"white"} height={20} width={20} />
            ) : (
              "Retry Payment"
            )}
          </button>
        )}
        {showViewOrderDetails && (
          <button
            className="btn btn-success mx-2 p-2"
            onClick={handleViewOrderDetails}
          >
            Show Order Details
          </button>
        )}
        <button
          className="btn btn-success mx-2 p-2"
          onClick={() => history.push("/")}
        >
          Shop More
        </button>
      </div>
    </div>
  );
};

export default StatusDisplay;
