import "bootstrap/dist/css/bootstrap.css";
import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import HomeBuilderRedirect from "../HomeBuilderRedirect/HomeBuilderRedirect";
import "./OfferBanner.css";

const OfferBanner = ({ componentDesignData }) => {
  const { getScaledFontSize } = useContext(AppContext);
  const { width } = useWindowSize();

  return (
    <div className="container">
      <HomeBuilderRedirect widget={componentDesignData?.subTemplate?.widget[0]}>
        <div
          className="d-flex align-items-center justify-content-between r-075 OfferBanner"
          style={{
            color: "white",
            cursor: "pointer",
            background: `linear-gradient(93.58deg, ${componentDesignData?.subTemplate?.widget[0].details.startGradient} 0%, ${componentDesignData?.subTemplate?.widget[0].details.endGradient} 100%)`,
          }}
        >
          <div className="offer-banner-wrapper">
            <>
              <span
                className="offer-banner-title"
                style={{
                  fontSize: `${getScaledFontSize(
                    componentDesignData?.subTemplate?.widget[0].details
                      .titleFontSize,
                    1.4
                  )}px`,
                }}
              >
                {componentDesignData?.subTemplate?.widget[0].details.title}
              </span>{" "}
              <span
                className="offer-banner-discount"
                style={{
                  fontSize: `${getScaledFontSize(
                    componentDesignData?.subTemplate?.widget[0].details
                      .discountLabelFontSize,
                    1.4
                  )}px`,
                }}
              >
                {
                  componentDesignData?.subTemplate?.widget[0].details
                    .discountLabel
                }
              </span>
            </>
          </div>
          <img
            src={
              width >= 445
                ? componentDesignData?.subTemplate?.widget[0].details
                    .imageUrlWeb
                  ? componentDesignData?.subTemplate?.widget[0].details
                      .imageUrlWeb
                  : componentDesignData?.subTemplate?.widget[0].details.imageUrl
                : componentDesignData?.subTemplate?.widget[0].details.imageUrl
            }
            alt="discount-banner"
            className="offer-banner-image"
          />
        </div>
      </HomeBuilderRedirect>
    </div>
  );
};

export default OfferBanner;
