import React, { useContext, useState } from "react";
import shareToFriend from "../../../../assets/images/share-to-friend.svg";
import copy from "../../../../assets/images/copy.svg";
import whatsapp from "../../../../assets/images/whatsapp.svg";
import facebook from "../../../../assets/images/facebook.svg";
import telegram from "../../../../assets/images/telegram.svg";
import twitter from "../../../../assets/images/twitter.svg";
import "./UserReferals.css";
import { AppContext } from "../../../../context/AppContext";

const UserReferals = () => {
  const { setShowToast, setToastData } = useContext(AppContext);
  const [referalCode, setReferalCode] = useState("3t3ztnz");

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(`https://nesto.shop/${referalCode}`);
    setShowToast(true);
    setToastData({
      type: "info",
      text: "Link copied!",
    });
  }

  return (
    <div className="share-to-friend-container">
      <img src={shareToFriend} />
      <p className="header">Share To Your Friend</p>
      <p className="description">
        Invite Your Friends To Nesto And Shop Together
      </p>
      <div className="referal-code-container py-3 px-4">
        <p className="code">{`nesto.shop/${referalCode}`}</p>
        <img src={copy} className="copy-icon" onClick={handleCopyToClipboard} />
      </div>
      <p className="code-display">{`Your Code: ${referalCode}`}</p>
      <div>
        <a
          target="_blank"
          href={`whatsapp://send?text=nesto.shop%2F${referalCode}`}
        >
          <img src={whatsapp} className="app-icon c-pointer" />
        </a>
        <a
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=nesto.shop%2F${referalCode}`}
        >
          <img src={facebook} className="app-icon c-pointer" />
        </a>
        <a
          target="_blank"
          href={`https://t.me/share/url?url=nesto.shop%2F${referalCode}`}
        >
          <img src={telegram} className="app-icon c-pointer" />
        </a>
        <a
          target="_blank"
          href={`https://twitter.com/intent/tweet?text=nesto.shop%2F${referalCode}`}
        >
          <img src={twitter} className="app-icon c-pointer" />
        </a>
      </div>
      <a
        className="share-btn c-pointer"
        target="_blank"
        href={`mailto:?&subject=Nesto%20Referal&body=nesto.shop%2F${referalCode}`}
        style={{ color: "white", textDecoration: "inherit" }}
      >
        Share Your Link
      </a>
    </div>
  );
};

export default UserReferals;
