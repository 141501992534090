import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useWindowSize from "../../hooks/useWindowSize";

export const ItemLoader = () => {
  return (
    <SkeletonTheme>
      <Skeleton reactangle={true} height={"7.1em"} width={"6.2em"} />
      {/* <Skeleton count={1} /> */}
    </SkeletonTheme>
  );
};

export const DealsItemLoader = () => {
  return (
    <SkeletonTheme>
      <Skeleton reactangle={true} height={"10.9em"} width={"100%"} />
    </SkeletonTheme>
  );
};

export const OrderItemLoader = () => {
  const { width } = useWindowSize();
  return (
    <div className="row">
      <div
        className={
          width < 950 ? "col-md-4" : width < 1120 ? "col-md-3" : "col-md-2"
        }
      >
        <div className="order-status-icon-box">
          <Skeleton reactangle={true} height={"100%"} width={"100%"} />
        </div>
      </div>
      <div
        className={
          (width < 950 ? "col-md-5" : width < 1120 ? "col-md-6" : "col-md-7") +
          " text-left"
        }
      >
        <div className="order-status-head mb-2">
          <Skeleton reactangle={true} height={"100%"} width={"100%"} />
        </div>
        <div className="order-status-details">
          <Skeleton reactangle={true} height={"100%"} width={"100%"} />
        </div>
        <div className="order-status-details">
          <Skeleton reactangle={true} height={"100%"} width={"100%"} />
        </div>
      </div>
      <div className="col-md-3 text-right">
        <div className={"order-status-no-items"}>
          <Skeleton reactangle={true} height={"100%"} width={"100%"} />
        </div>
        <div className="order-status-total-amount">
          <Skeleton reactangle={true} height={"100%"} width={"100%"} />
        </div>
      </div>
    </div>
  );
};
export const OrderListLoader = () => {
  const listRow = [];
  for (let i = 0; i < 5; i++) {
    listRow.push(
      <div className="item-container-selectable my-2 px-3 py-4">
        <OrderItemLoader />
      </div>
    );
  }

  return (
    <SkeletonTheme>
      <span className="profile-title my-2">
        <Skeleton reactangle={true} height={"120px"} />
      </span>
      <Skeleton reactangle={true} height={"500px"} />
    </SkeletonTheme>
  );
};

export const OrderDetailsLoader = () => {
  return (
    <>
      <div className="col-md-12">
        <CustomStepperLoader />
      </div>
      <div className="row justify-content-between">
        <div className="col status-container">
          <div className="mb-5">
            <OrderItemLoader />
          </div>
          <div className="row justify-content-between">
            <div className="col-md-6 my-2" style={{ height: "65px" }}>
              <Skeleton reactangle={true} height={"100%"} width={"100%"} />
            </div>
            <div className="col-md-6 my-2" style={{ height: "65px" }}>
              <Skeleton reactangle={true} height={"100%"} width={"100%"} />
            </div>
            <div className="col-md-6 my-2" style={{ height: "65px" }}>
              <Skeleton reactangle={true} height={"100%"} width={"100%"} />
            </div>
            <div className="col-md-6 my-2" style={{ height: "65px" }}>
              <Skeleton reactangle={true} height={"100%"} width={"100%"} />
            </div>
          </div>
          <div className="mt-5 justify-content-between">
            <div className="p-2 address-view-header">Payment Method</div>
          </div>
        </div>
        <div className="col-md-4 address-view-container px-0">
          <Skeleton reactangle={true} height={"100%"} width={"100%"} />
        </div>
      </div>
    </>
  );
};
export const CustomStepperLoader = () => {
  return (
    <SkeletonTheme>
      <div className={"col"} style={{ height: "75px" }}>
        <Skeleton reactangle={true} height={"100%"} />
      </div>
    </SkeletonTheme>
  );
};

export const OrderStatusConfirmLoader = () => {
  return (
    <SkeletonTheme>
      <div className="row" style={{ height: "400px" }}>
        <div className="col-md-9">
          <Skeleton reactangle={true} height={"100%"} />
        </div>
        <div className="col-md-3">
          <Skeleton reactangle={true} height={"100%"} />
        </div>
      </div>
    </SkeletonTheme>
  );
};
export const ProductDetailsLoader = () => {
  const { height } = useWindowSize();
  return (
    <SkeletonTheme>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-5">
                <Skeleton reactangle={true} height={height / 1.5} />
              </div>
              <div className="col-md-7">
                <Skeleton reactangle={true} height={height / 1.5} />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <Skeleton reactangle={true} height={height / 2.5} />
          </div>
        </div>

        <div className="row pt-5">
          <div className="nav-container col-md-12">
            <Skeleton reactangle={true} height={height / 5} />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export const EditOrderLoader = () => {
  const { height } = useWindowSize();
  return (
    <SkeletonTheme>
      <div className="order-edit-page py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <Skeleton reactangle={true} height={height / 4} />
            </div>
            <div className="col-md-4">
              <Skeleton reactangle={true} height={height / 2} />
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};
