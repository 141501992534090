import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { getOrders } from "../../../../api";
import EmptyData from "../../../../components/EmptyData";
import OrderItem from "../../../../components/OrderItem/OrderItem";
import { OrderListLoader } from "../../../../components/SkeletonLoader";

import emptycart from "../../../../assets/images/empty-cart.svg";

const UserOrders = () => {
  const [orderList, setOrderList] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("active");

  useEffect(() => {
    setOrderLoading(true);
    getOrders()
      .then((res) => setOrderList(res[0].orders))
      .catch((e) => console.log(e.message))
      .finally(() => setOrderLoading(false));
  }, []);
  const activeOrders = orderList?.filter(
    (item) =>
      item.status === "processing" ||
      item.status === "pending" ||
      item.status === "holded" ||
      item.status === "payment_review" ||
      item.status === "pending_payment" ||
      item.status === "picking_initiated" ||
      item.status === "picking_completed" ||
      item.status === "packing_initiated" ||
      item.status === "packing_completed" ||
      item.status === "ready_for_delivery" ||
      item.status === "out_for_delivery"
  );
  const completedOrders = orderList?.filter(
    (item) =>
      item.status === "complete" ||
      item.status === "delivered" ||
      item.status === "return_initiated" ||
      item.status === "return_collected" ||
      item.status === "returned"
  );
  const closedOrders = orderList?.filter(
    (item) =>
      item.status === "payment_failed" ||
      item.status === "cancelled" ||
      item.status === "closed" ||
      item.status === "fraud" ||
      item.status === "canceled"
  );

  return (
    <div>
      {orderLoading ? (
        <OrderListLoader />
      ) : (
        <>
          <span className="profile-title my-2">My Orders</span>
          <div className="nav-tab py-3">
            <ul className="col-xl-4 col-lg-5 col-md-7 col-sm-6 nav nav-bars c-pointer d-flex mb-3">
              <li
                className={
                  "details-tab " +
                  (activeTab === "active" ? "active active-details-tab" : "")
                }
              >
                <a
                  onClick={() => {
                    setActiveTab("active");
                  }}
                >
                  On-going
                </a>
              </li>
              <li
                className={
                  "details-tab " +
                  (activeTab === "active" ? "" : "active active-details-tab")
                }
              >
                <a
                  onClick={() => {
                    setActiveTab("completed");
                  }}
                >
                  Completed
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className={
                  "tab-pane " + (activeTab === "active" ? "active" : "")
                }
              >
                {activeOrders.length > 0 ? (
                  <OrderStatusType title="Active Orders" data={activeOrders} />
                ) : (
                  <div className="empty-product">
                    <EmptyData
                      picture={emptycart}
                      heading={"No Active Orders"}
                      description={"Create an order to view them here"}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="tab-content">
              <div
                className={
                  "tab-pane " + (activeTab === "completed" ? "active" : "")
                }
              >
                {completedOrders.length > 0 || closedOrders.length > 0 ? (
                  <>
                    {completedOrders.length > 0 && (
                      <OrderStatusType
                        title="Completed Orders"
                        data={completedOrders}
                      />
                    )}
                    {closedOrders.length > 0 && (
                      <OrderStatusType
                        title="Failed & Cancelled Orders"
                        data={closedOrders}
                      />
                    )}
                  </>
                ) : (
                  <div className="empty-product">
                    <EmptyData
                      picture={emptycart}
                      heading={"No Completed Orders"}
                      description={"Create an order to view them here"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserOrders;

const OrderStatusType = (props) => {
  const [slicer, setSlicer] = useState(10);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const fetchMoreListItems = () => {
    setTimeout(() => {
      if (slicer < props?.data?.length) {
        setSlicer((old) => old + 10);
        setPage((old) => old + 1);
      } else {
        setHasMore(false);
      }
    }, 800);
  };

  return (
    <div className="container p-0">
      <div className="profile-title my-2">{props.title}</div>
      <InfiniteScroll
        dataLength={10 * page - 2} //This is important field to render the next data
        next={fetchMoreListItems}
        hasMore={hasMore}
        loader={
          <div className="d-flex w-100 justify-content-center align-items-center p-2">
            <Loader
              type="ThreeDots"
              color={"mediumseagreen"}
              height={25}
              width={50}
            />
          </div>
        }
        // endMessage={
        //   <p style={{ textAlign: "center" }}>
        //     <b>Yay! You have seen it all</b>
        //   </p>
        // }
      >
        {props?.data?.slice(0, slicer).map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item?.items?.length > 0 && (
                <Link
                  style={{ color: "inherit", textDecoration: "inherit" }}
                  to={"/orderdetails/" + item?.entity_id}
                >
                  <div className="item-container-selectable my-2 p-3">
                    <OrderItem
                      itemData={item}
                      status={item?.status?.toLowerCase()}
                    />
                  </div>
                </Link>
              )}
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};
