import React from "react";
import OrderStatusData from "../../utils/OrderStatusData";
import "./OrderItem.css";
import moment from "moment";

const OrderItem = (props) => {
  return (
    <div className="row d-flex align-items-center">
      <span className="col-xl-2 col-md-3 col-sm-4 col-5">
        <div className={`${OrderStatusData(props?.status)?.styleName}`}>
          <img
            className="mx-auto my-4 d-block"
            src={OrderStatusData(props?.status)?.imgSrc}
            width={50}
            height={50}
          />
        </div>
      </span>
      <span className="col-xl-7 col-md-6 col-sm-5 col-7">
        <div className="order-status-head mb-2 text-capitalize">
          {OrderStatusData(props?.status)?.orderHeads}
        </div>
        <div className="order-status-details">
          {moment(moment.utc(props?.itemData?.created_at).toDate())
            .local()
            .format("LLLL")}
        </div>
        <div className="order-status-details">
          {"#" + props?.itemData?.increment_id}
        </div>
      </span>
      <span className="col-sm-3 col-12 text-sm-right my-2">
        <div
          className={
            "order-status-no-items c-pointer" +
            (props?.type === "inside" && " underline")
          }
          onClick={() =>
            props?.type === "inside" && props?.handleItemShow(true)
          }
        >
          {props?.itemData?.items?.length === 1
            ? "1 item"
            : props?.itemData?.items?.length + " items"}
        </div>
        <div className="order-status-total-amount">
          {props?.itemData?.base_currency_code +
            " " +
            parseFloat(props?.itemData?.base_grand_total).toFixed(2)}
        </div>
      </span>
    </div>
  );
};

export default OrderItem;
