import React, { useEffect } from "react";
import cookie from "../../assets/images/cookie.svg";
import "./Cookies.css";

function Cookies({ cookiesAccepted, updateCookiesAccepted }) {
  function handleCookieClose() {
    document.getElementById("cookies").classList.add("cookies-cancelled");
    setTimeout(() => {
      document.getElementById("cookies").style.display = "none";
    }, 300);
  }
  useEffect(() => {
    if (cookiesAccepted !== false) {
      document.getElementById("cookies").style.display = "none";
    } else {
      document.getElementById("cookies").style.display = "block";
    }
  }, [cookiesAccepted]);

  const acceptCookies = () => {
    updateCookiesAccepted();
  };

  return (
    <div id="cookies" className="cookies">
      <div className="cookie-wrap d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex mb-3 mb-md-0">
          <img src={cookie} alt="cookie" className="cookie-img" />
          <p className="cookie-text">
            We use cookies to enhance your browsing experience. By using our
            site you consent cookies.
          </p>
        </div>
        <div className="d-flex">
          {/* ADD CLICK FUNCTION FOR ACCEPTING COOKIES */}
          <button
            className="cookie-accept click-anim c-pointer"
            onClick={acceptCookies}
          >
            Accept All Cookies
          </button>
          <button
            className="cookie-close click-anim c-pointer"
            onClick={handleCookieClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cookies;
