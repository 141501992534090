import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";
import useWindowSize from "../../../../hooks/useWindowSize";
import styles from "./DealsV2MainItem.module.css";

const DealsV2MainItem = ({
  widgetId,
  widgetCode,
  details,
  subTitle1,
  currentPrice,
  originalPrice,
}) => {
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const { width } = useWindowSize();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    redirectType === "EXTERNAL_LINK"
      ? window.open(url, "_blank")
      : history.push(url);
  };
  return (
    <div
      style={{
        paddingRight: "0rem",
        paddingLeft: "0rem",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      }}
      onClick={() => handleRedirect(details?.redirectType, details?.itemCode)}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img
          style={{ width: "222px" }}
          src={
            width >= 445
              ? details?.imageUrlWeb
                ? details?.imageUrlWeb
                : details?.imageUrl
              : details?.imageUrl
          }
          alt={details?.name}
        />
        <div className={styles.title}>{subTitle1}</div>
      </div>
      <div
        className="d-flex justify-content-start align-items-end"
        style={{ marginTop: "0.5rem" }}
      >
        <div className={styles["current-price"]}>{currentPrice}</div>
        <div className={styles["original-price"]}>{originalPrice}</div>
      </div>
    </div>
  );
};

export default DealsV2MainItem;
