import React, { useState, useEffect, useContext, useRef } from "react";
import "./Profile.css";
import { useHistory } from "react-router-dom";
import { MdFavoriteBorder } from "react-icons/md";

import { BiWallet, BiBell, BiStar } from "react-icons/bi";
import {
  IoCartOutline,
  IoSettingsOutline,
  IoLogOutOutline,
  IoStarOutline,
  IoLocationOutline,
  IoGlobeOutline
} from "react-icons/io5";
import { RiArrowRightSLine } from "react-icons/ri";
import { AppContext } from "../../context/AppContext";
import {
  UserReferals,
  ProfileDetails,
  UserOrders,
  AddressBook,
  UserWishlist,
  RewardPoints,
  PaymentMethods,
  UserSettings,
  UserNotifications,
  UserStatistics,
  AboutNesto,
  PrivacyPolicy,
  WriteFeedback,
  ContactUs
} from "./Modules";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import { getInaamPoints } from "../../api";
import useWindowSize from "../../hooks/useWindowSize";
import { confirmAlert } from "react-confirm-alert";

const Profile = props => {
  const {
    profile,
    isLoggedIn,
    setShowLogin,
    logout,
    inaamData,
    setInaamData,
    setBreadcrumbs
  } = useContext(AppContext);
  const getInaamData = () => {
    getInaamPoints(
      profile?.custom_attributes
        ?.filter(item => {
          return item?.attribute_code === "mobile_number";
        })[0]
        ?.value?.slice(0, 3) === "+91"
        ? profile?.custom_attributes
            ?.filter(item => {
              return item?.attribute_code === "mobile_number";
            })[0]
            ?.value.slice(3)
        : profile?.custom_attributes
            ?.filter(item => {
              return item?.attribute_code === "mobile_number";
            })[0]
            ?.value.slice(4)
    ).then(res => setInaamData(res));
  };

  useEffect(() => {
    profile?.custom_attributes?.filter(item => {
      return item?.attribute_code === "mobile_number";
    })[0]?.value && getInaamData();
  }, [isLoggedIn]);

  const history = useHistory();
  const [profileItem, setProfileItem] = useState();

  const onItemClick = e => {
    history.push("/account" + e);
  };
  useEffect(() => {
    setProfileItem("/" + props.match.params.section);
    setBreadcrumbs([
      { title: "Home", path: "/" },
      { title: props.match.params.section }
    ]);
  }, [props.match.params.section]);

  useDocumentTitle(props.match.params.section + " - Nesto Online Shopping");

  const { width } = useWindowSize();

  const handleLogout = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body confirm-popup-button-body">
            <h2 className="fw-700">{"Logout?"}</h2>
            <div>{"Are you sure to log out?"}</div>
            <div className="btn-group d-flex justify-content-end">
              <button
                className={"confirm-popup-button confirm-button-delete"}
                onClick={() => {
                  logout();
                  onClose();
                }}
              >
                {"Logout"}
              </button>
              <button
                className={"cancel-popup-button cancel-button-delete"}
                onClick={onClose}
              >
                No
              </button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: true
    });
  };

  return (
    <>
      {isLoggedIn ? (
        <>
          <div className="container">
            <div className="row">
              {width > 768 && (
                <div className="col-lg-3 col-md-4 col-sm-12 my-3">
                  <div className="menu-container">
                    <MenuUserCard heading="profile">
                      <MenuUserCardItem
                        className={` ${
                          props.match.params.section === "profile"
                            ? "selected"
                            : ""
                        }`}
                        mainHead={`${profile?.firstname || "FirstName"} ${
                          profile?.lastname === "."
                            ? ""
                            : profile?.lastname || "LastName"
                        }`}
                        onClick={() => onItemClick("/profile")}
                      >
                        <div className="user-card-details">
                          {profile?.email}
                        </div>
                        <div className="user-card-details">
                          {profile?.custom_attributes?.filter(item => {
                            return item?.attribute_code === "mobile_number";
                          })[0]?.value || "Phone"}
                        </div>
                      </MenuUserCardItem>
                      {inaamData?.card_no && (
                        <MenuUserCardItem
                          className={` ${
                            props.match.params.section === "inaam"
                              ? "selected"
                              : ""
                          }`}
                          mainHead={`INAAM Points`}
                          onClick={() => onItemClick("/inaam")}
                        >
                          <div className="user-card-details">
                            {`${parseFloat(inaamData?.total).toFixed(2)} INAAM
                          Points`}
                          </div>
                          {/* <Barcode value={inaamData?.card_no} /> */}
                        </MenuUserCardItem>
                      )}
                    </MenuUserCard>
                    <MenuCard heading="order information">
                      <MenuItem onClick={() => onItemClick("/orders")}>
                        <IoCartOutline
                          size={20}
                          color={
                            props.match.params.section === "orders"
                              ? "#00983d"
                              : ""
                          }
                        />
                        <span
                          className={`col-md-9 justify-content-start ${
                            props.match.params.section === "orders"
                              ? "selected"
                              : ""
                          }`}
                        >
                          my orders
                        </span>
                      </MenuItem>
                      {/* <MenuItem onClick={() => onItemClick("/payment")}>
                    <BiWallet size={20} />
                    <span className="col-md-9 justify-content-start">
                      payment methods
                    </span>
                  </MenuItem>
                  */}
                      <MenuItem onClick={() => onItemClick("/address")}>
                        <IoLocationOutline
                          size={20}
                          color={
                            props.match.params.section === "address"
                              ? "#00983d"
                              : ""
                          }
                        />
                        <span
                          className={`col-md-9 justify-content-start ${
                            props.match.params.section === "address"
                              ? "selected"
                              : ""
                          }`}
                        >
                          address book
                        </span>
                      </MenuItem>
                    </MenuCard>
                    <MenuCard heading="Application">
                      {/* <MenuItem onClick={() => onItemClick("/settings")}>
                    <IoSettingsOutline size={20} />
                    <span className="col-md-9 justify-content-start">
                      settings
                    </span>
                  </MenuItem>
                  */}
                      <MenuItem onClick={() => onItemClick("/notification")}>
                        <BiBell
                          size={20}
                          color={
                            props.match.params.section === "notification"
                              ? "#00983d"
                              : ""
                          }
                        />
                        <span
                          className={`col-md-9 justify-content-start ${
                            props.match.params.section === "notification"
                              ? "selected"
                              : ""
                          }`}
                        >
                          notifications
                        </span>
                      </MenuItem>
                      <MenuItem onClick={() => onItemClick("/wishlist")}>
                        <MdFavoriteBorder
                          size={20}
                          color={
                            props.match.params.section === "wishlist"
                              ? "#00983d"
                              : ""
                          }
                        />
                        <span
                          className={`col-md-9 justify-content-start ${
                            props.match.params.section === "wishlist"
                              ? "selected"
                              : ""
                          }`}
                        >
                          my wishlist
                        </span>
                      </MenuItem>
                      {/* <MenuItem onClick={() => onItemClick("/referals")}>
                      <TiTags
                        size={20}
                        color={
                          props.match.params.section === "referals"
                            ? "#00983d"
                            : ""
                        }
                      />
                      <span
                        className={`col-md-9 justify-content-start ${
                          props.match.params.section === "referals"
                            ? "selected"
                            : ""
                        }`}
                      >
                        refer a friend
                      </span>
                    </MenuItem> */}
                      {/* <MenuItem onClick={() => onItemClick("/statistics")}>
                    <RiBarChart2Line size={20} />
                    <span className="col-md-9 justify-content-start">
                      my statistics
                    </span>
                  </MenuItem> */}
                    </MenuCard>
                    <MenuCard heading="help">
                      <MenuItem onClick={() => onItemClick("/about")}>
                        <IoGlobeOutline
                          size={20}
                          color={
                            props.match.params.section === "about"
                              ? "#00983d"
                              : ""
                          }
                        />
                        <span
                          className={`col-md-9 justify-content-start ${
                            props.match.params.section === "about"
                              ? "selected"
                              : ""
                          }`}
                        >
                          about nesto
                        </span>
                      </MenuItem>
                      {/* <MenuItem onClick={() => onItemClick("/privacypolicy")}>
                    <BsShieldLock size={20} />
                    <span className="col-md-9 justify-content-start">
                      privacy policy
                    </span>
                  </MenuItem> */}
                      {/* <MenuItem onClick={() => onItemClick("/feedback")}>
                    <RiLifebuoyLine
                      size={20}
                      color={
                        props.match.params.section === "feedback"
                          ? "#00983d"
                          : ""
                      }
                    />
                    <span
                      className={`col-md-9 justify-content-start ${
                        props.match.params.section === "feedback"
                          ? "selected"
                          : ""
                      }`}
                    >
                      write feedback
                    </span>
                  </MenuItem> */}
                      {/* <MenuItem onClick={() => onItemClick("/contactus")}>
                    <HiOutlineChatAlt size={20} />
                    <span className="col-md-9 justify-content-start">
                      contact us
                    </span>
                  </MenuItem> */}

                      <MenuItem onClick={handleLogout} title="Logout">
                        <IoLogOutOutline size={20} />
                        <span className="col-md-9 justify-content-start">
                          Logout
                        </span>
                      </MenuItem>
                    </MenuCard>
                  </div>
                </div>
              )}
              <div className="col-lg-9 col-md-8 col-sm-12 my-3">
                <ProfileItemSelector currentItem={profileItem} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container">
          <div className="offset-md-5 col-md-2">
            <button
              className="btn btn-success"
              onClick={() => setShowLogin(true)}
            >
              Login
            </button>
            <div>Login to Continue</div>
          </div>
        </div>
      )}
    </>
  );
};
const MenuUserCard = props => {
  return (
    <div className="container-fluid d-flex flex-column py-2">
      <div className="menu-card-heading">{props.heading}</div>
      {props.children}
    </div>
  );
};
const MenuUserCardItem = props => {
  return (
    <div
      className="container-fluid d-flex justify-content-between menu-card-body py-2 c-pointer align-items-center"
      onClick={props.onClick}
    >
      <div>
        <div className="user-card-heading">
          <span className={props?.className}>{props.mainHead}</span>
        </div>
        <div>{props.children}</div>
      </div>
      {props?.onClick && <RiArrowRightSLine size={25} />}
    </div>
  );
};

const MenuCard = props => {
  return (
    <div className="container-fluid d-flex flex-column py-2">
      <div className="menu-card-heading py-2">{props.heading}</div>
      <div className="menu-card-body">
        {props.children.length > 0 ? (
          props.children.map((item, index) => {
            return <div key={index}>{item}</div>;
          })
        ) : (
          <div>{props.children}</div>
        )}
      </div>
    </div>
  );
};

const MenuItem = props => {
  return (
    <div
      className="container-fluid d-flex justify-content-between menu-card-item py-2"
      onClick={props.onClick}
      style={props.style}
    >
      <div>{props.children}</div>
      <div>{props?.onClick && <RiArrowRightSLine size={25} />}</div>
    </div>
  );
};
const ProfileItemSelector = ({ currentItem }) => {
  const [content, setContent] = useState();
  const profileContainer = useRef(null);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    switch (currentItem) {
      case "/profile":
        setContent(<ProfileDetails />);
        break;
      case "/inaam":
        setContent(<RewardPoints />);
        break;
      case "/orders":
        setContent(<UserOrders />);
        break;
      // case "/payment":
      //   setContent( <PaymentMethods />);
      // break;
      case "/address":
        setContent(<AddressBook />);
        break;
      // case "/settings":
      //   setContent( <UserSettings />);
      // break;
      case "/notification":
        setContent(<UserNotifications />);
        break;
      case "/wishlist":
        setContent(<UserWishlist />);
        break;
      case "/referals":
        setContent(<UserReferals />);
        break;
      // case "/statistics":
      //   setContent( <UserStatistics />);
      // break;
      case "/about":
        setContent(<AboutNesto />);
        break;
      // case "/privacypolicy":
      //   setContent( <PrivacyPolicy />);
      // break;
      // case "/feedback":
      //   setContent( <WriteFeedback />);
      // break;
      // case "/contactus":
      //   setContent( <ContactUs />);
      // break;
      default:
        setContent(<PageNotFound />);
        break;
    }
  }, [currentItem]);

  function getPos(el) {
    // yay readability
    for (
      var lx = 0, ly = 0;
      el != null;
      lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent
    );
    return { x: lx, y: ly };
  }

  return <div ref={profileContainer}>{content}</div>;
};

const PageNotFound = () => {
  return <>PageNotFound</>;
};
export default Profile;
