export const getAddressString = (addr) => {
  let area = addr?.area_name;
  let city = addr?.city;
  let street = addr?.street;

  const ifIsString = (v, last = false) => {
    if (v && v !== ".") {
      return v + (last ? "" : ", ");
    }
    return "";
  };

  if (addr?.residence_type === "villa") {
    let villa_number = addr?.villa_number;
    let street_number = addr?.street_number;

    return (
      ifIsString(villa_number) +
      ifIsString(street_number) +
      ifIsString(street) +
      ifIsString(area) +
      ifIsString(city, true)
    );
  } else {
    let bulding_name = addr?.building_name;
    let flat_number = addr?.flat_number;

    return (
      ifIsString(bulding_name) +
      ifIsString(flat_number) +
      ifIsString(street) +
      ifIsString(area) +
      ifIsString(city, true)
    );
  }
};
