import React, { useEffect } from "react";
import Lottie from "react-lottie";
import { useLocation } from "react-router";
import notfound from "../../assets/animations/404.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const ErrorNotFound = props => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  useDocumentTitle("Page Not Found - Nesto Online Shopping");
  const location = useLocation();
  const defaultOptions = {
    autoplay: true,
    loop: true,
    animationData: notfound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div
      className="container d-flex flex-column align-items-center text-center px-1 py-0"
      style={{ zIndex: "999999" }}
    >
      <div className="mx-3 my-0">
        <Lottie options={defaultOptions} width={"80%"} height={"100%"} />
        <div className="h2">{"404 - Page Not Found"}</div>
        <div className="fs-16">
          {
            "The page you are trying to access does not exist or is not accessible."
          }
        </div>
        {location.pathname !== "/" ? (
          <button
            className="btn btn-success m-4"
            onClick={() => {
              window.history.go(-1);
            }}
          >
            Go Back
          </button>
        ) : (
          <button
            className="btn btn-success m-4"
            onClick={() => {
              window.history.go(0);
            }}
          >
            Reload
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorNotFound;
