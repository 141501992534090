import React, { useContext, useEffect, useState } from "react";
import {
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiMail,
  FiPhoneCall,
  FiPlus,
  FiMinus,
} from "react-icons/fi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useHistory, useLocation } from "react-router";
import nestoCartLogo from "../../assets/images/nesto-cart-logo.png";
import payIcons from "../../assets/images/pay-icons.svg";
import { AppContext } from "../../context/AppContext";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const history = useHistory();
  const location = useLocation();
  const [urls, setUrls] = useState({});
  const [selectedFaq, setSelectedFaq] = useState();
  const faqs = [
    {
      id: 1,
      que: "How to get started?",
      ans: "Enter the City and Area that you stay and you can start shopping.",
    },
    {
      id: 2,
      que: "How do I register to INAAM?",
      ans: "It's as easy as downloading the INAAM App on your phone.",
    },
    {
      id: 3,
      que: "How do I return my orders?",
      ans: "You can return your orders through your recent orders page or request the delivery agent to initiate return for you.",
    },
  ];

  const updateSelectedFaq = (id) => {
    if (selectedFaq === id) {
      return setSelectedFaq(null);
    }
    setSelectedFaq(id);
  };

  const { currentStore, isLoggedIn, setShowToast, setToastData, setShowLogin } =
    useContext(AppContext);
  useEffect(() => {
    if (process?.env?.REACT_APP_ENV === "production") {
      setUrls({
        grocery: 2612,
        beverages: 2678,
        breakfast: 2637,
        condiments: 2613,
        canned: 2632,
      });
    } else {
      setUrls({
        grocery: 1630,
        beverages: 1694,
        breakfast: 1655,
        condiments: 1631,
        canned: 1650,
      });
    }
  }, []);

  const pageNavigation = (url, authRequired = false) => {
    if (!isLoggedIn & authRequired) {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Login to Continue",
      });
      setShowLogin(true);
      return 0;
    }
    if (currentStore?.is_active !== "1") {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Select Location to Continue",
      });
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return 0;
    }
    history.push(url);
  };

  return (
    <div
      className={`footer ${
        location.pathname === "/checkout" ? "d-none d-md-block" : ""
      }`}
    >
      <div className="faq">
        <div className="faq-header mb-3">Frequently Asked Questions</div>
        {faqs.map((faq) => {
          return (
            <div className="faq-qa" key={faq.id}>
              <div
                className="d-flex"
                onClick={() => updateSelectedFaq(faq.id)}
                style={{ cursor: "pointer" }}
              >
                <div className="faq-icon">
                  {faq.id === selectedFaq ? <FiMinus /> : <FiPlus />}
                </div>
                <div
                  className={`faq-q ${faq.id === selectedFaq ? "mb-2" : ""}`}
                >
                  {faq.que}
                </div>
              </div>
              {faq.id === selectedFaq && <div className="faq-a">{faq.ans}</div>}
            </div>
          );
        })}
      </div>
      <div className="d-flex flex-column flex-sm-row align-items-start justify-content-between">
        <div className="nesto-logo-section mb-4 mb-sm-0 align-self-center">
          <img src={nestoCartLogo} alt="" className="mb-4" />
          <div className="tagline">All that you need.</div>
          <div className="d-flex align-items-center justify-content-between">
            <a
              href="https://www.instagram.com/nesto.shop/"
              target="_blank"
              rel="noopen noreferrer"
            >
              <FiInstagram size="18px" />
            </a>
            <a
              href="https://www.facebook.com/nestoshopuae"
              target="_blank"
              rel="noopen noreferrer"
            >
              <FiFacebook size="18px" />
            </a>
            <a
              href="https://www.linkedin.com/company/nestogroup/"
              target="_blank"
              rel="noopen noreferrer"
            >
              <FiLinkedin size="18px" />
            </a>
          </div>
        </div>
        <div className="link-list mb-4 mb-sm-0">
          <div className="heading">About Us</div>
          <ul className="">
            <li>
              <Link to="/account/about">About Nesto</Link>
            </li>
          </ul>
          <div className="heading mt-5">Help</div>
          <ul className="">
            <li>
              <Link to="/account/orders">Orders and Returns</Link>
            </li>
          </ul>
        </div>
        <div className="link-list d-none d-lg-block mb-4 mb-sm-0">
          <div className="heading">Policy</div>
          <ul className="">
            <li>
              <a
                href="https://docs.nesto.shop/Terms.html"
                target="_blank"
                rel="noopen noreferrer"
              >
                Terms &amp; Conditions
              </a>
            </li>
            <li>
              <a
                href="https://docs.nesto.shop/Privacy.pdf"
                target="_blank"
                rel="noopen noreferrer"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <div className="link-list d-none d-lg-block mb-4 mb-sm-0">
          <div className="heading">In The Spotlight</div>
          <ul className="">
            <li>
              <Link to={`/categories/${urls.grocery}`}>Pantry Supplies</Link>
            </li>
            <li>
              <Link to={`/categories/${urls.beverages}`}>Beverages</Link>
            </li>
            <li>
              <Link to={`/categories/${urls.breakfast}`}>
                Breakfast &amp; Cereal
              </Link>
            </li>
            <li>
              <Link to={`/categories/${urls.condiments}`}>Condiments</Link>
            </li>
            <li>
              <Link to={`/categories/${urls.canned}`}>Canned Goods</Link>
            </li>
          </ul>
        </div>
        <div className="link-list mb-4 mb-sm-0">
          <div className="heading">Contact Us</div>
          <ul className="">
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=97148129700"
                target="_blank"
                rel="noopen noreferrer"
              >
                <AiOutlineWhatsApp size="18px" />
                <span className="ml-2">+971-48129700</span>
              </a>
            </li>
            <li>
              <a href="tel:+97148129700">
                <FiPhoneCall />
                <span className="ml-2">+971-48129700</span>
              </a>
            </li>
            <li>
              <a href="mailto:support@nesto.shop">
                <FiMail />
                <span className="ml-2">support@nesto.shop</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr className="divider-gray" />
      <div className="d-flex flex-column flex-sm-row align-items-start align-items-md-center justify-content-between">
        <div className="trademark mb-2 mb-sm-0">
          © 2021 Nesto Ltd. Trademarks and brands
        </div>
        <img className="" src={payIcons} alt="pay-icons" />
      </div>
    </div>
  );
};

export default Footer;
