import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import "../../pages/HomepageNew/HomepageNew.css";
import HomeBuilderRedirect from "../HomeBuilderRedirect/HomeBuilderRedirect";

const AdBanner = ({ componentDesignData }) => {
  const [widget, setWidget] = useState({});
  const { width } = useWindowSize();

  useEffect(() => {
    setWidget({ ...componentDesignData?.subTemplate?.widget?.[0] });
  }, [componentDesignData]);

  return (
    <HomeBuilderRedirect widget={widget}>
      <div
        className="container"
        style={{
          width: "100%",
          margin: "auto",
          marginTop: width > 445 ? "1rem" : "0.5rem",
          marginBottom: width > 445 ? "1rem" : "0.5rem",
          maxHeight: "160px",
          cursor: "pointer",
        }}
      >
        <img
          src={
            width >= 445
              ? widget?.details?.imageUrlWeb
                ? widget?.details?.imageUrlWeb
                : widget?.details?.imageUrl
              : widget?.details?.imageUrl
          }
          alt="Ad-Banner"
          style={{ width: "100%", maxHeight: "160px" }}
        />
      </div>
    </HomeBuilderRedirect>
  );
};

export default AdBanner;
