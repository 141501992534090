import React from "react";
import "./ButtonSpinner.css";

const ButtonSpinner = ({ isLoading, buttonText }) => {
  return (
    <div className="button-spinner-container">
      {!isLoading ? (
        <span>{buttonText}</span>
      ) : (
        <div className="button-spinner" />
      )}
    </div>
  );
};

export default ButtonSpinner;
