import React, { useEffect, useState, useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import moment from "moment";
import { markNotificationsRead } from "../../api";
import "./Notification.css";
function NotificationItem({ data }) {
  const [seen, setSeen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [date, setDate] = useState("");
  const bodyRef = useRef();

  useEffect(() => {
    let createdAt = moment.utc(data?.createdAt).local();
    setDate(moment(createdAt).fromNow());
    setSeen(data?.seen);
  }, []);

  function handleReadNotification() {
    let payload = {
      notification_ids: [data.id]
    };
    markNotificationsRead(payload).then(res =>
      res?.success ? setSeen(true) : ""
    );
  }

  function handleExpand() {
    setExpand(!expand);
    if (!seen) {
      handleReadNotification();
    }
  }

  return (
    <div className="notification-item c-pointer" onClick={handleExpand}>
      <p className="notification-title">
        {data?.title}
        <FiChevronDown
          className={"ml-1 spinner-arrow" + (expand ? " spin180" : "")}
        />
        {!seen ? <span className="unread"></span> : ""}
      </p>
      <p
        className="notification-content"
        ref={bodyRef}
        style={{ height: expand ? bodyRef.current.scrollHeight + "px" : "0px" }}
      >
        {data?.body}
      </p>
      <p className="notification-time">{date}</p>
    </div>
  );
}

export default NotificationItem;
