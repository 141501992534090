import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionContext,
  useAccordionToggle
} from "react-bootstrap";
import { useHistory } from "react-router";
import { AppContext } from "../../context/AppContext";
import { RiArrowRightSLine } from "react-icons/ri";
import "./CategorySideMenu.css";
import useWindowSize from "../../hooks/useWindowSize";

const CategorySideMenu = props => {
  const { categories } = useContext(AppContext);
  const [catArray, setCatArray] = useState([]);

  const { width } = useWindowSize();
  const filterCatId = id => {
    let tempArray = [];
    categories?.children_data &&
      categories?.children_data?.map(i1 => {
        tempArray = [
          {
            id: i1?.id,
            path: "/categories/" + i1?.id,
            title: i1?.name
          }
        ];
        if (i1?.id === id) {
          setCatArray(tempArray);
        } else {
          i1?.children_data &&
            i1?.children_data?.map(i2 => {
              tempArray = [
                { id: i1?.id, path: "/categories/" + i1?.id, title: i1?.name },
                { id: i2?.id, path: "/categories/" + i2?.id, title: i2?.name }
              ];
              if (i2?.id === id) {
                setCatArray(tempArray);
              } else {
                i2?.children_data &&
                  i2?.children_data?.map(i3 => {
                    tempArray = [
                      {
                        id: i1?.id,
                        path: "/categories/" + i1?.id,
                        title: i1?.name
                      },
                      {
                        id: i2?.id,
                        path: "/categories/" + i2?.id,
                        title: i2?.name
                      },
                      {
                        id: i3?.id,
                        path: "/categories/" + i3?.id,
                        title: i3?.name
                      }
                    ];
                    if (i3?.id === id) {
                      setCatArray(tempArray);
                    }
                  });
              }
            });
        }
      });
  };
  useEffect(() => {
    filterCatId(props?.catId);
  }, [props?.catId]);
  return (
    <div className="cat-side-menu-container">
      <div className="cat-side-menu-body-container fw-500">
        {catArray && categories?.children_data && (
          <MenuMega
            item={categories?.children_data}
            catArray={catArray}
            setSidebar={props?.setSidebar ? props?.setSidebar : null}
          />
        )}
      </div>
    </div>
  );
};

const MenuMega = ({ item, catArray, setSidebar }) => {
  const [megaActive, setMegaActive] = useState("");
  useEffect(() => {
    setMegaActive(catArray[0]?.id);
  }, [catArray[0]?.id]);

  return (
    <Accordion activeKey={megaActive} className="py-2">
      {item?.map((itemData, index) => {
        return itemData?.children_data?.length !== 0 ? (
          <AccordianItem
            setActive={setMegaActive}
            itemData={itemData}
            className="cat-side-menu-mega"
            key={index}
          >
            <MenuMain
              item={itemData?.children_data}
              catArray={catArray}
              setSidebar={setSidebar ? setSidebar : null}
            />
          </AccordianItem>
        ) : (
          <NonAccordianItem
            key={index}
            itemData={itemData}
            currentCatId={catArray[0]?.id}
            className="cat-side-menu-mega"
            setSidebar={setSidebar ? setSidebar : null}
          />
        );
      })}
    </Accordion>
  );
};

const MenuMain = ({ item, catArray, setSidebar }) => {
  const [mainActive, setMainActive] = useState("");
  useEffect(() => {
    setMainActive(catArray[1]?.id);
  }, [catArray[1]?.id]);

  return (
    <Accordion activeKey={mainActive} className="py-2">
      {item?.map((itemData, index) => {
        return itemData?.children_data?.length !== 0 ? (
          <AccordianItem
            itemData={itemData}
            className="cat-side-menu-main"
            key={index}
            setActive={setMainActive}
          >
            <MenuSub
              item={itemData?.children_data}
              catArray={catArray}
              setSidebar={setSidebar ? setSidebar : null}
            />
          </AccordianItem>
        ) : (
          <NonAccordianItem
            key={index}
            itemData={itemData}
            currentCatId={catArray[1]?.id}
            className="cat-side-menu-main"
            setSidebar={setSidebar ? setSidebar : null}
          />
        );
      })}
    </Accordion>
  );
};

const MenuSub = ({ item, catArray, setSidebar }) => {
  return (
    <div className="py-2">
      {item?.map((itemData, index) => {
        return (
          <NonAccordianItem
            key={index}
            itemData={itemData}
            currentCatId={catArray[2]?.id}
            className="cat-side-menu-sub"
            setSidebar={setSidebar ? setSidebar : null}
          />
        );
      })}
    </div>
  );
};

const NonAccordianItem = ({
  currentCatId,
  itemData,
  className,
  index,
  setSidebar
}) => {
  const history = useHistory();
  return (
    <div
      key={index}
      className={`c-pointer d-flex justify-content-between px-2 ${className} ${
        currentCatId === itemData?.id && "text-success"
      }`}
    >
      <span
        className="cat-side-menu-item no-text-select"
        onClick={() => {
          history.push(`/categories/${itemData?.id}`);
          setSidebar && setSidebar(false);
        }}
      >
        {itemData?.name}
      </span>
    </div>
  );
};

const AccordianItem = ({ itemData, children, className, index, setActive }) => {
  return (
    <div className={className} key={index}>
      <CustomToggle
        eventKey={itemData?.id}
        onClickID={itemData?.id}
        setActive={setActive}
      >
        {itemData?.name}
      </CustomToggle>
      <Accordion.Collapse eventKey={itemData?.id} className="px-3">
        {children}
      </Accordion.Collapse>
    </div>
  );
};

const CustomToggle = ({
  children,
  eventKey,
  onClickID,
  callback,
  style,
  setActive
}) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    callback && callback(eventKey);
    if (currentEventKey === eventKey) setActive("-1");
    else setActive(eventKey);
  });
  const isCurrentEventKey = currentEventKey === eventKey;
  const history = useHistory();
  return (
    <div
      style={{ ...style, color: isCurrentEventKey && "mediumseagreen" }}
      className="mx-2 c-pointer d-flex justify-content-between"
      onClick={() => {
        decoratedOnClick();
      }}
      // onMouseEnter={() => {
      //   decoratedOnClick();
      // }}
    >
      <span
        className="cat-side-menu-item no-text-select"
        onClick={() => {
          history.push(`/categories/${onClickID}`);
        }}
      >
        {children}
      </span>
      <RiArrowRightSLine
        size={25}
        className={
          "arrow-rotate" + (isCurrentEventKey === true ? " spin90" : "")
        }
      />
    </div>
  );
};

export default CategorySideMenu;
