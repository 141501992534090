export const checkCustomAttributeValue = (name, obj) => {
  if (!obj.custom_attributes || obj.custom_attributes.length === 0) {
    return "";
  }
  let req = obj.custom_attributes.filter((v) => v.attribute_code === name);
  if (req.length === 0) {
    return "";
  }
  return req[0].value;
};
