import "bootstrap/dist/css/bootstrap.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowSize from "../../hooks/useWindowSize";
import "../../pages/HomepageNew/HomepageNew.css";
import compareWidgetsPosition from "../../utils/compareWidgetsPosition";
import HomeBuilderRedirect from "../HomeBuilderRedirect/HomeBuilderRedirect";
import "./TwoBannerGrid.css";
import TwoBannerImageV1 from "./widgets/TwoBannerImageV1";
import TwoBannerImageV2 from "./widgets/TwoBannerImageV2";

SwiperCore.use([Navigation]);

const TwoBannerGrid = ({ componentDesignData }) => {
  const { width } = useWindowSize();

  const orderWidgets = (widget, index) => {
    switch (widget.widgetCode) {
      case "TWO_BANNER_IMAGE_V1": {
        return <TwoBannerImageV1 widget={widget} key={widget._id} />;
      }
      case "TWO_BANNER_IMAGE_V2": {
        return <TwoBannerImageV2 widget={widget} key={widget._id} />;
      }
      default:
        return null;
    }
  };

  return (
    <div
      className="container"
      style={{
        marginTop: width > 425 ? "1rem" : "0.5rem",
        marginBottom: width > 425 ? "1rem" : "0.5rem",
      }}
    >
      {componentDesignData?.subTemplate?.widget[0]?.widgetCode ===
        "TWO_BANNER_IMAGE_V1" && (
        <>
          <h2 className="homepage_title">
            {componentDesignData?.subTemplate?.details.title}
          </h2>
          <div className="two-grid-wrapper">
            {componentDesignData?.subTemplate?.widget
              .sort(compareWidgetsPosition)
              .map((widget, index) => orderWidgets(widget, index))}
          </div>
        </>
      )}
      {componentDesignData?.subTemplate?.widget[0]?.widgetCode ===
        "TWO_BANNER_IMAGE_V2" && (
        <div className={`slider-grid-wrapper-${componentDesignData?.position}`}>
          <div
            className="d-flex justify-content-between justify-content-md-start align-items-center"
            style={{ marginTop: "0.5rem" }}
          >
            <h1 className="homepage_title">
              {componentDesignData?.subTemplate?.details.title}
            </h1>
            <div
              style={{
                color: "#2D9140",
                marginLeft: "0.5rem",
                marginBottom: "12px",
                fontWeight: "700",
                fontSize: "0.875rem",
                cursor: "pointer",
              }}
            >
              <HomeBuilderRedirect widget={componentDesignData?.subTemplate}>
                View all <IoIosArrowForward />
              </HomeBuilderRedirect>
            </div>
          </div>
          <div className="swipe-arrows slide-arrows">
            <div className="swipe-button-prev">
              <FiChevronLeft className="swipe-arrow" />
            </div>
            <div className="swipe-button-next">
              <FiChevronRight className="swipe-arrow" />
            </div>
            <Swiper
              spaceBetween={5}
              slidesPerView={"auto"}
              navigation={{
                nextEl: `.slider-grid-wrapper-${componentDesignData?.position} .swipe-button-next`,
                prevEl: `.slider-grid-wrapper-${componentDesignData?.position} .swipe-button-prev`,
              }}
              observer={true}
              observeParents={true}
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              {componentDesignData?.subTemplate?.widget
                .sort(compareWidgetsPosition)
                .map((widget, index) => (
                  <SwiperSlide
                    key={widget._id}
                    style={{ flexShrink: "1", maxWidth: "364px" }}
                  >
                    {orderWidgets(widget, index)}
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

export default TwoBannerGrid;
