import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import emptyStar from "../../assets/images/empty-star.svg";
import start from "../../assets/images/Star.svg";
import "./Rating.css";
import { FiStar } from "react-icons/fi";

const Rating = (props) => {
  const handleClose = () => props.setShow(false);
  const [starType, setStarType] = useState([
    { id: 1, type: "star" },
    { id: 2, type: "star" },
    { id: 3, type: "star" },
    { id: 4, type: "star" },
    { id: 5, type: "star" },
  ]);
  const [rating, setRating] = useState("0");

  const handleChange = (fieldIndex) => {
    if (rating === fieldIndex) {
      fieldIndex = 0;
      setRating(0);
    } else {
      setRating(fieldIndex);
    }
    const beforeStars = starType.filter(
      (star) => star.id <= parseInt(fieldIndex, 10)
    );
    const afterStars = starType.filter(
      (star) => star.id > parseInt(fieldIndex, 10)
    );
    beforeStars?.map((star) => {
      star.type = "fill-star";
    });
    afterStars?.map((star) => {
      star.type = "star";
    });
    setStarType([...beforeStars, ...afterStars]);
  };

  return (
    <Modal
      className={"rating-popup " + (props.show ? "modal-anim" : "")}
      show={props.show}
      onHide={handleClose}
      animation={false}
      centered
    >
      <p className="heading">Rate Your Previous Delivery</p>
      <p className="info">
        On a scale of 1 to 5, how would you rate your experience with Nesto for
        your previous order?
      </p>
      <div className="input-element">
        {starType?.map((starData, index) => (
          <FiStar
            key={index}
            className={starData.type}
            id={starData.id}
            onClick={(e) => {
              handleChange(starData.id);
            }}
          />
        ))}
      </div>
      <button
        className="continue-btn"
        onClick={() => {
          handleClose();
        }}
      >
        Continue
      </button>
    </Modal>
  );
};

export default Rating;
