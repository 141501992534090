import React from "react";
import Lottie from "react-lottie";
import Loading from "../../assets/animations/basket-loading.json";
import "./MainLoader.css";

const MainLoader = ({ outerClassName, innerClassName }) => {
  const defaultOptions = {
    autoplay: true,
    loop: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      <div className={`text-center ${outerClassName}`}>
        <div className={`container ${innerClassName} d-flex flex-column`}>
          <Lottie options={defaultOptions} width={"30%"} height={"30%"} />
          <p className="description">Hey, We are reaching out for your items</p>
        </div>
      </div>
    </>
  );
};
export default MainLoader;
