import refresh from "../assets/images/sync.svg";
import inTransit from "../assets/images/in-transit-blue.svg";
import cross from "../assets/images/cross-red.svg";
import tick from "../assets/images/tick-green.svg";
import packed from "../assets/images/packed.svg";
const OrderStatusData = (status) => {
  let styleName;
  let orderHeads;
  let imgSrc;
  let step;
  let returnOrder = false;
  switch (status) {
    case "pending":
      styleName = "order-status-icon-box order-status-packed";
      orderHeads = "Order is Pending";
      imgSrc = packed;
      step = 0;
      break;

    case "picking_initiated":
      styleName = "order-status-icon-box order-status-packed";
      orderHeads = "Order is Being Picked";
      imgSrc = packed;
      step = 0.5;
      break;

    case "picking_completed":
      styleName = "order-status-icon-box order-status-packed";
      orderHeads = "Order Picked";
      imgSrc = packed;
      step = 1;
      break;

    case "packing_initiated":
      styleName = "order-status-icon-box order-status-packed";
      orderHeads = "Order is Being Packed";
      imgSrc = packed;
      step = 1.5;
      break;

    case "packing_completed":
      styleName = "order-status-icon-box order-status-packed";
      orderHeads = "Order Packed";
      imgSrc = packed;
      step = 2;
      break;

    case "payment_review":
      styleName = "order-status-icon-box order-status-waiting";
      orderHeads = "Payment Review";
      imgSrc = refresh;
      step = 0;
      break;

    case "pending_payment":
      styleName = "order-status-icon-box order-status-waiting";
      orderHeads = "Payment Pending";
      imgSrc = refresh;
      step = 0;
      break;

    case "processing":
      styleName = "order-status-icon-box order-status-waiting";
      orderHeads = "Order is Being Processed";
      imgSrc = refresh;
      step = 0;
      break;

    case "holded":
      styleName = "order-status-icon-box order-status-waiting";
      orderHeads = "Order is On-Hold";
      imgSrc = refresh;
      step = 1;
      break;

    // crossIcon
    case "payment_failed":
      styleName = "order-status-icon-box order-status-failed";
      orderHeads = "Payment Failed";
      imgSrc = cross;
      step = -1;
      break;

    case "closed":
      styleName = "order-status-icon-box order-status-failed";
      orderHeads = "Order Closed";
      imgSrc = cross;
      step = -1;
      break;

    case "canceled":
    case "cancelled":
      styleName = "order-status-icon-box order-status-failed";
      orderHeads = "Order Cancelled";
      imgSrc = cross;
      step = -1;
      break;

    case "fraud":
      styleName = "order-status-icon-box order-status-failed";
      orderHeads = "Suspected Fraud";
      imgSrc = cross;
      step = -1;
      break;

    //deliveryIcon
    case "ready_for_delivery":
      styleName = "order-status-icon-box order-status-in-transit";
      orderHeads = "Ready for Delivery";
      imgSrc = inTransit;
      step = 2.5;
      break;

    case "out_for_delivery":
      styleName = "order-status-icon-box order-status-in-transit";
      orderHeads = "On The Way";
      imgSrc = inTransit;
      step = 3;
      break;

    //completedIcon
    case "complete":
    case "delivered":
      styleName = "order-status-icon-box order-status-success";
      orderHeads = "Delivered";
      imgSrc = tick;
      step = 4;
      break;
    //  retuned

    case "return_initiated":
      styleName = "order-status-icon-box order-status-waiting";
      orderHeads = "Return Initiated";
      imgSrc = refresh;
      step = 5;
      returnOrder = true;
      break;

    case "return_collected":
      styleName = "order-status-icon-box order-status-success";
      orderHeads = "Return Collected";
      imgSrc = tick;
      step = 6;
      returnOrder = true;
      break;

    case "returned":
      styleName = "order-status-icon-box order-status-success";
      orderHeads = "Order Returned";
      imgSrc = tick;
      step = 7;
      returnOrder = true;
      break;

    default:
      styleName = "order-status-icon-box order-status-packed";
      orderHeads = "Order Status Unknown";
      imgSrc = packed;
      step = -1;
      break;
  }

  return { styleName, orderHeads, imgSrc, step, returnOrder };
};

export default OrderStatusData;
