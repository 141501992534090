import React from "react";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import placeholder from "../../assets/images/placeholder.svg";
import "./OrderItemView.css";
const OrderItemView = ({ item, view }) => {
  const history = useHistory();

  const onProductClick = () => {
    history.push(`/products/${item.sku}`);
  };
  return (
    <div
      className={
        "order-item-container fs-14" + (view === "modal" ? " row m-0 p-0" : "")
      }
      onClick={onProductClick}
    >
      <div
        className={
          "order-item-image-container" +
          (view === "modal" ? " col-md-1 col-2 pl-0" : "")
        }
      >
        <span className="order-item-qty">{parseInt(item?.qty_ordered)}</span>
        <img src={placeholder} alt="product" height="60px" />
      </div>
      <div
        className={
          "order-item-name" + (view === "modal" ? " col-md-8 col-6" : "")
        }
      >
        {item?.name}
      </div>
      <div
        className={
          "order-item-price" + (view === "modal" ? " col-3 text-right" : "")
        }
      >
        {`AED ${parseFloat(item?.base_price_incl_tax).toFixed(2)}`}
      </div>
    </div>
  );
};

export default OrderItemView;
