import React from "react";
import logo from "../../../../assets/images/nesto-logo2.png";

const AboutNesto = () => {
  return (
    <div className="item-container px-4">
      <div className="row justify-content-center m-5">
        <img src={logo} alt="logo" />
      </div>
      <div className="row px-5">
        <h3>Who We Are</h3>
        <p>
          Nesto Hypermarket is a well-known name across homes in the Gulf
          region. Founded by Mr. K P Basheer, Chairman of Western International
          Group in 2004 and led by directors Mr. Siddique Pallolathil & Mr.Jamal
          Kp, Nesto has been one of the fastest growing retail chains in the
          Gulf Cooperation Council (GCC) locations.
        </p>
        <p>
          The Group’s tagline – ‘Nesto, all that you need’ – represents the
          company’s vision of being a one-stop shop for the people in the
          region. It caters to the multi-ethnic community residing in all its
          locations and therefore, stocks a huge amount of international
          products besides the locally manufactured items.
        </p>
      </div>
      <div className="row px-5">
        <h3>Our Mission</h3>
        <p>
          To provide a great shopping experience and integral part of our loyal
          customers lives, by offering high quality shopping experiences through
          good product, quality at even better prices.
        </p>
      </div>
    </div>
  );
};
export default AboutNesto;
