import { AiOutlineStar, AiTwotoneStar } from "react-icons/ai";
import useWindowSize from "../../hooks/useWindowSize";
import LaptopImage2 from "./assets/LaptopImage2.png";
import "./DynamicSectionList.css";

const data = {
  title: "Shop Latest Laptops now",
  widget: [
    {
      title:
        "ASUS ROG Strix G17 (2021) 17.3-inch (43.94 cms) FHD 144Hz, AMD Ryzen 7 4800H, GTX 1650 4GB ßGraphics, Gaming Laptop...",
      rating: 4,
      numberOfReviews: 1246,
      originalPrice: 3202,
      discountedPrice: 2600,
      freeDeliveryBy: "Thursday, Nov 11",
      imageUrl: LaptopImage2,
      imageBackgroundColor: "#F2F2F2",
      backgroundColor: "#FFFFFF",
      discount: "10%",
    },
    {
      title:
        "ASUS ROG Strix G17 (2021) 17.3-inch (43.94 cms) FHD 144Hz, AMD Ryzen 7 4800H, GTX 1650 4GB Graphics, Gaming Laptop...",
      rating: 4,
      numberOfReviews: 1246,
      originalPrice: 3202,
      discountedPrice: 2600,
      freeDeliveryBy: "Thursday, Nov 11",
      imageUrl: LaptopImage2,
      imageBackgroundColor: "#F2F2F2",
      backgroundColor: "#FFFFFF",
      discount: "10%",
    },
    {
      title:
        "ASUS ROG Strix G17 (2021) 17.3-inch (43.94 cms) FHD 144Hz, AMD Ryzen 7 4800H, GTX 1650 4GB Graphics, Gaming Laptop...",
      rating: 4,
      numberOfReviews: 1246,
      originalPrice: 3202,
      discountedPrice: 2600,
      freeDeliveryBy: "Thursday, Nov 11",
      imageUrl: LaptopImage2,
      imageBackgroundColor: "#F2F2F2",
      backgroundColor: "#FFFFFF",
      discount: "10%",
    },
  ],
};

function DynamicSectionList({ componentDesignData }) {
  const { width } = useWindowSize();

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  return (
    <div className="container">
      <h2 className="homepage_title">
        {componentDesignData?.subTemplate?.details?.title}
      </h2>
      <div className="widget-wrapper">
        {data.widget.map((widget, index) => (
          <div
            key={widget._id}
            style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
          >
            <div className="d-flex" style={{ border: "1px solid #F2F2F2" }}>
              <div
                className="d-flex justify-content-center align-items-center dynamic-list-image-wrapper"
                style={{ background: widget.imageBackgroundColor }}
              >
                <img
                  className="dynamic-list-image"
                  src={widget.imageUrl}
                  alt="product"
                />
              </div>
              <div className="dynamic-list-content-wrapper">
                <div className="dynamic-list-title">
                  {truncateString(widget.title, 60)}
                </div>
                <div className="dynamic-list-rating d-flex">
                  {new Array(widget.rating).fill(undefined).map((_, index) => (
                    <AiTwotoneStar size={18} color="#FF8514" key={index} />
                  ))}
                  {new Array(5 - widget.rating)
                    .fill(undefined)
                    .map((_, index) => (
                      <AiOutlineStar size={18} color="#FF8514" key={index} />
                    ))}
                  <div className="dynamic-list-reviews">
                    ({widget.numberOfReviews})
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-end dynamic-list-price-wrapper">
                  <div className="dynamic-list-discounted-price d-flex">
                    AED {widget.discountedPrice}
                  </div>
                  <div
                    className="dynamic-list-original-price"
                    style={{ textDecoration: "line-through" }}
                  >
                    AED {widget.originalPrice}
                  </div>
                  <div className="dynamic-list-discount">
                    Save AED {widget.originalPrice - widget.discountedPrice} (
                    {widget.discount})
                  </div>
                </div>
                <div className="dynamic-list-delivery">
                  Free Delivery by{" "}
                  <span className="dynamic-list-delivery-days">
                    {widget.freeDeliveryBy}
                  </span>
                </div>
              </div>
            </div>
            {index !== data.widget.length - 1 && width > 678 && (
              <div
                style={{
                  width: "2px",
                  backgroundColor: "#F2F2F2",
                  marginRight: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DynamicSectionList;
