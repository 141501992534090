import React from "react";
import Lottie from "react-lottie";
import maintenance from "../../assets/animations/maintenance.json";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const SiteUnderMaintenance = props => {
  const defaultOptions = {
    autoplay: true,
    loop: true,
    animationData: maintenance,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  useDocumentTitle("Site Under Maintenance - Nesto Online Shopping");
  return (
    <div className="container d-flex flex-column align-items-center text-center pt-5 px-1">
      <div className="mx-3 mt-5">
        <div className="h3">{props?.title}</div>
        <Lottie options={defaultOptions} width={"75%"} height={"75%"} />
        <div className="fs-16">{props?.message}</div>
        <button
          className="btn btn-success m-4"
          onClick={() => document.location.reload()}
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default SiteUnderMaintenance;
