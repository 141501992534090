import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../../../context/AppContext";
import Favourites from "../../../Favourites";

const UserWishlist = () => {
  const { setWishListUpdated } = useContext(AppContext);
  useEffect(() => {
    setWishListUpdated((old) => !old);
  }, []);

  return (
    <div>
      <span className="profile-title my-2 mx-3">Favourites</span>
      <Favourites />
    </div>
  );
};

export default UserWishlist;
