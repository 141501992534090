import React, { useContext, useEffect, useState } from "react";
import ProductGrid from "../../components/ProductGrid";
import EmptyData from "../../components/EmptyData";
import emptycart from "../../assets/images/empty-cart.svg";
import { AppContext } from "../../context/AppContext";
import MainLoader from "../../components/MainLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { searchProduct } from "../../api";
import useDocumentTitle from "../../hooks/useDocumentTitle";

function Search(props) {
  const [page, setPage] = useState(2);
  const [limit, setLimit] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [endMessage, setEndMessage] = useState();
  const [searchProducts, setSearchProducts] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const { setBreadcrumbs, currentStore, setSearchProductName } =
    useContext(AppContext);

  useEffect(() => {
    setBreadcrumbs([{ title: "Home", path: "/" }, { title: "Search" }]);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    setHasMore(true);
    setPage(2);
    getSearchData();
  }, [props?.location?.search]);

  const getSearchData = () => {
    setIsPageLoading(true);
    currentStore?.store_id &&
      searchProduct(
        props?.location?.search.replace("?name=", ""),
        currentStore?.store_id,
        1,
        limit
      )
        .then((res) => {
          if (res?.length === 0) {
            setHasMore(false);
            setEndMessage(true);
          }
          setSearchProducts(res);
        })
        .finally(() => {
          setIsPageLoading(false);
        });
  };
  useDocumentTitle(
    "Search - " +
      props?.location?.search.replace("?name=", "") +
      " - Nesto Online Shopping"
  );
  const fetchMoreListItems = () => {
    searchProduct(
      props?.location?.search.replace("?name=", ""),
      currentStore?.store_id,
      page,
      limit
    )
      .then((res) => {
        if (res?.length < 10) {
          setHasMore(false);
          setEndMessage(true);
        }
        setSearchProducts([...searchProducts, ...res]);
        setPage((old) => old + 1);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  return !isPageLoading ? (
    searchProducts.length > 0 ? (
      <div className="container py-3">
        <InfiniteScroll
          dataLength={limit * page - 12} //This is important field to render the next data
          next={fetchMoreListItems}
          hasMore={hasMore}
          loader={
            <div className="d-flex w-100 justify-content-center align-items-center p-2">
              <Loader
                type="ThreeDots"
                color={"mediumseagreen"}
                height={25}
                width={50}
              />
            </div>
          }
          endMessage={
            endMessage && (
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            )
          }
        >
          <ProductGrid data={searchProducts} type="nomenu" />
        </InfiniteScroll>
      </div>
    ) : (
      <div className="d-flex justify-content-center align-items-center py-3">
        <EmptyData
          picture={emptycart}
          heading={"Search Again!"}
          description={"No results available for this search."}
        />
      </div>
    )
  ) : (
    <MainLoader innerClassName="product-list-loader-animation" />
  );
}

export default Search;
