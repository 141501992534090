import React from "react";
import "./EmptyData.css";

const EmptyData = ({ picture, heading, description }) => {
  return (
    <div className="empty-data mt-0 pt-0">
      <img src={picture} className="mt-0 pt-0" />
      <p className="heading">{heading}</p>
      <p className="description">{description}</p>
    </div>
  );
};

export default EmptyData;
