import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import { clearAllNotifications, getNotification } from "../../../../api";
import { MdClearAll } from "react-icons/md";
import Loader from "react-loader-spinner";
import NotificationItem from "../../../../components/NotificationItem";
import "./UserNotifications.css";

const UserNotification = () => {
  const [notification, setNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clear, setClear] = useState(false);
  const { profile, setShowToast, setToastData } = useContext(AppContext);

  useEffect(() => {
    getNotification(profile.id)
      .then(res => {
        let filteredRes = res?.data.filter(
          x => x.data.action != "substitution"
        );
        setNotification(filteredRes);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function handleClearNotification() {
    if (notification.length === 0) {
      setShowToast(true);
      setToastData({
        type: "info",
        text: `No Notifications to clear`
      });
      return;
    }
    let payloadArray = [];
    notification.map(item => payloadArray.push(item.id));
    let payload = {
      notification_ids: payloadArray
    };
    setClear(true);
    clearAllNotifications(payload)
      .then(() => {
        setNotification([]);
      })
      .finally(() => {
        setClear(false);
      });
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <p className="m-0 notification-heading">Notifications</p>
        <button
          className="m-0 notification-clear"
          onClick={handleClearNotification}
        >
          <MdClearAll className={"mr-1" + (clear ? " flips" : "")} size={20} />
          Clear All
        </button>
      </div>
      <div className="row">
        {isLoading ? (
          <div className="col-md-12 text-center">
            <Loader
              type="ThreeDots"
              color={"mediumseagreen"}
              height={70}
              width={70}
            />
          </div>
        ) : (
          <></>
        )}
        {notification.length === 0 && !isLoading ? (
          <div className="col-md-12 text-center my-5">
            <b>All caught up!</b>
            <br />
            Shop more to see some awesome notifications :)
          </div>
        ) : (
          <></>
        )}
        {notification.map(i => {
          return (
            <div key={i.id} className="col-lg-12 mb-3">
              <NotificationItem data={i} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default UserNotification;
