import React, { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import Barcode from "react-barcode";

const RewardPoints = () => {
  const { inaamData } = useContext(AppContext);
  return (
    <>
      <div className="d-flex-column justify-content-between mb-3">
        <span className="profile-title my-2 mx-3">Inaam Points</span>
      </div>
      <div className="row">
        <div className="fw-900 col-md-6 order-2 order-md-1 text-center text-md-left">
          <div className="fs-18">Current Points</div>
          <div>{`${parseFloat(inaamData?.total).toFixed(
            2
          )} INAAM Points (AED ${parseFloat(inaamData?.total / 20).toFixed(
            2
          )})`}</div>
        </div>
        <div className="col-md-6 order-1 order-md-2 text-center text-md-right">
          <div className="px-3 fs-18 fw-900">Inaam ID</div>
          <Barcode value={inaamData?.card_no} />
        </div>
      </div>
    </>
  );
};
export default RewardPoints;
